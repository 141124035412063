
export const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const checkString = (
  stringToCheck: string | null | undefined,
  returnValue: string = "k.A."
) => {
  if (stringToCheck == undefined || stringToCheck === "") {
    return returnValue;
  } else {
    return stringToCheck;
  }
};

export const getBirthday = (object: any) => {
  if (object.Birthday !== null) {
    return new Date(object.Birthday).toLocaleString("de-DE", options);
  } else {
    return "Keine Angabe";
  }
};

export const checkTranslation = (
  t: any,
  tAttr: string,
  defaultTitle: string
) => {
  if (t == undefined || t(tAttr) === "" || t(tAttr) === tAttr) {
    return defaultTitle;
  } else {
    return t(tAttr);
  }
};

export const getDateOfDeath = (object: any) => {
  if (object.DateOfDeath !== null) {
    return new Date(object.DateOfDeath).toLocaleString("de-DE", options);
  } else {
    return "Keine Angabe";
  }
};

export const formatDate = (date: string | null | undefined) => {
  if (!date) {
    return "Keine Angabe";
  }

  return new Date(date).toLocaleString("de-DE", options);
};

/*
export const handlePostcode = (localObject: any) => {
    if (localObject.idPostcode != null
        && localObject.idPostcode > 0
        && localObject.Postcode != undefined
    ) {
        return {
            idPostcode: localObject.idPostcode,
            Postcode: localObject.Postcode,
            idCountry: 1
        } as IDistrictCourt
    } else {
        return null;
    }
}
*/
