import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { uploadFetch, useFetch } from "../../../hooks/useFetch";
import {
  Alert,
  Box,
  Button,
  Fade,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { CustomeSlide } from "../../core/CustomeSlide";
import Send from "../../generic/Send";
import { IContact, IContactFullObject } from "../../../Interfaces/IContact";
import { ICompany } from "../../../Interfaces/ICompany";
import { ClientInformation } from "../../generic/ClientInformation";
import { IConfig } from "../../../Interfaces/IConfig";
import { DialogFrame } from "../../core/DialogFrame";

interface IProps {
  configObject: IConfig;
}

export const InviteDialogAddContact: React.FC<IProps> = ({ configObject }) => {
  const params = useParams();
  const [tokenValide, setTokenValide, wasSuccessfully] = useFetch<{
    Text: string | null;
    Email: string;
  }>("/contactinvite/contact/validate/", params.token);
  //
  const [currentPage, setCurrentPage] = useState(0);
  //
  const [isConfirmed, setIsConfirmed] = useState<null | boolean>(null);
  //
  const [isLoading, setIsLoading] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [wasSuccessfullyUploaded, setWasSuccessfullyUploaded] = useState(true);
  //
  const [personObject, setPersonObject] = useState<IContact>({
    idContact: -1,
    idCompany: -1,
    idGender: 1,
    idAcademicTitle: 1,
    FirstName: "",
    LastName: "",
    Email: "",
  } as IContact);

  const [companyObject, setCompanyObject] = useState<ICompany>({
    idCompany: -1,
    Company: "",
    idBusinessForm: 1,
    idPostcode: null,
    Street: "",
    StreetNr: "",
    StreetAditional: "",
    PhoneNumber: "",
    Website: "",
    RegisterCourt: null,
    RegisterNumber: null,
    TaxNumber: null,
    SalesTaxNumber: null,
  });

  const handleSend = () => {
    const uploadObject = {
      TempToken: params.token,
      isAssumed: isConfirmed,
      ContactObject: {
        ...personObject,
        Email: String(tokenValide?.Email),
        CompanyObject: companyObject,
      } as IContactFullObject,
    };
    uploadFetch(
      `/contactinvite/answer/${params.token}`,
      true,
      uploadObject,
      () => setCurrentPage(2),
      setWasSuccessfullyUploaded,
      setWasSaved,
      setIsLoading
    );
  };

  const handleNext = () => {
    if (currentPage === 1 || (isConfirmed !== null && isConfirmed === false)) {
      handleSend();
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const contextTSx = () => {
    if (!wasSuccessfully) {
      return (
        <>
          <Alert severity="warning">
            Die Einladung konnte nicht gefunden werden oder ist bereits
            abgelaufen.
            <br />
            In dringenden Fällen kontaktieren Sie uns bitte.
          </Alert>
        </>
      );
    } else if (!wasSuccessfullyUploaded) {
      return (
        <>
          <Alert severity="error">
            Ihre Antwort konnte nicht hochgeladen werden.
            <br />
            In dringenden Fällen kontaktieren Sie uns bitte.
          </Alert>
        </>
      );
    } else if (isLoading || tokenValide === undefined) {
      return (
        <>
          <CustomCircularProgress />
        </>
      );
    } else {
      return (
        <>
          <CustomeSlide
            currentPage={currentPage}
            pageNumber={0}
            lastCurrentPage={0}
            appear={false}
          >
            <>
              <Alert severity="info">
                <Typography variant="body1">
                  Sie wurden zum Mandantenportal von{" "}
                  <i>{configObject.company_name}</i> eingeladen.
                  {tokenValide.Text !== null && tokenValide.Text !== "" && (
                    <>
                      <br />
                      Es wurde folgende Nachricht für Sie hinterlegt:
                      <Box sx={{ mt: 2, mb: 2 }}>{tokenValide.Text}</Box>
                    </>
                  )}
                  Wenn Sie die Einladung annehmen, wird der entsprechende
                  Vorgang Ihrem Benutzerkonto im Mandantenportal zugeordnet.
                  <p />
                  Möchten Sie die Einladung zum Vorgang annehmen?
                </Typography>
              </Alert>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="error"
                  variant={
                    isConfirmed === null || isConfirmed
                      ? "outlined"
                      : "contained"
                  }
                  onClick={() => setIsConfirmed(false)}
                  sx={{ mr: 1 }}
                >
                  Ablehnen
                </Button>

                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  color="success"
                  variant={
                    isConfirmed === null || !isConfirmed
                      ? "outlined"
                      : "contained"
                  }
                  onClick={() => setIsConfirmed(true)}
                >
                  Annehmen
                </Button>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Fade in={isConfirmed !== null}>
                  <Button variant="contained" onClick={handleNext}>
                    {isConfirmed !== null && isConfirmed ? (
                      <>Weiter</>
                    ) : (
                      <>Absenden</>
                    )}
                  </Button>
                </Fade>
              </Box>
            </>
          </CustomeSlide>

          <CustomeSlide
            currentPage={currentPage}
            pageNumber={1}
            lastCurrentPage={0}
          >
            <ClientInformation
              configObject={configObject}
              contactFullObject={
                {
                  ...personObject,
                  CompanyObject: companyObject,
                } as IContactFullObject
              }
              setContactFullObject={(localFullObject: IContactFullObject) => {
                setPersonObject(localFullObject);
                setCompanyObject(localFullObject.CompanyObject);
              }}
              handleBack={() => setCurrentPage(currentPage - 1)}
              handleSave={handleSend}
              disableHasAccount
              staticEmail={tokenValide.Email}
              isTranslationEnabled={false}
            />
          </CustomeSlide>

          <CustomeSlide
            currentPage={currentPage}
            pageNumber={2}
            lastCurrentPage={1}
          >
            <Send />
          </CustomeSlide>
        </>
      );
    }
  };

  return (
    <>
      <DialogFrame
        configObject={configObject}
        title="Einladung zum Vorgang"
        currentPage={currentPage}
        disableHorizontalWarning
        stepperArray={[
          <Step key="invite0" completed={currentPage > 0}>
            <StepLabel>Vorgang bestätigen</StepLabel>
          </Step>,
          <Step key="invite0" completed={currentPage > 0}>
            <StepLabel>Konto erstellen</StepLabel>
          </Step>,
        ]}
      >
        <>{contextTSx()}</>
      </DialogFrame>
    </>
  );
};
