import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { IHandelsregister } from "../../Interfaces/IHandelsregister";
import { CustomCircularProgress } from "./CustomCircularProgress";
import { ICompanyFormation } from "../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../Interfaces/IShareHolder";
import { ICompanyFormationHasShareHolder } from "../../Interfaces/ICompanyFormationHasShareHolder";
import { ICompany } from "../../Interfaces/ICompany";
import { ArrowDropDown } from "@mui/icons-material";
import { DialogResponsive } from "../core/DialogResponsive";
import { ICourt } from "../../Interfaces/IAemter";

interface IProps {
  searchLabel: string;

  openImportFromHandelsregister: boolean;
  setOpenImportFromHandelsregister: Function;

  wasSuccessfullImportedFromhandelsregister: boolean;
  setWasSuccessfullImportedFromhandelsregister: Function;

  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;

  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;

  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;

  registerArt?: string; // optional prop hinzufügen
}

const bundeslaender = [
  "Baden-Württemberg",
  "Bayern",
  "Berlin",
  "Brandenburg",
  "Bremen",
  "Hamburg",
  "Hessen",
  "Mecklenburg-Vorpommern",
  "Niedersachsen",
  "Nordrhein-Westfalen",
  "Rheinland-Pfalz",
  "Saarland",
  "Sachsen",
  "Sachsen-Anhalt",
  "Schleswig-Holstein",
  "Thüringen",
];

const registerArten = ["alle", "HRA", "HRB", "GnR", "PR", "VR"];

export const HandelsregisterSearch: React.FC<IProps> = (props) => {
  const [searchString, setSearchString] = useState("");
  const [selectedSearch, setSelectedSearch] = useState({
    court: "",
    name: "",
    state: "",
    status: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);
  const [showImportSuccesfullMessage, setShowImportSuccesfullMessage] =
    useState(false);
  const [showWasSuccessfully, setShowWasSuccessfully] = useState(false);

  const [handelsreisterSearchRes, setHandelsreisterSearchRes] = useState<
    null | undefined | IHandelsregister[]
  >(null);

  const [companyName, setCompanyName] = useState("");

  //Suchoptionen

  //const [districtCourtArray, setDistrictCourtArray, wasSuccessfullDistrictCourtArray] = useFetch<IDistrictCourt[]>("/districtcourt");
  //const [tradeRegisterArray, setTradeResgisterArray] = useState<IDistrictCourt[]>([])

  const [schlagwortOptionen, setSchlagwortOptionen] = React.useState(1);

  const [checkedSuchOptionenAehnlich, setCheckedSuchOptionenAehnlich] =
    React.useState(false);
  const [checkedSuchOptionenGeloescht, setCheckedSuchOptionenGeloescht] =
    React.useState(false);
  const [
    checkedSuchOptionenNurZNneuenRechts,
    setCheckedSuchOptionenNurZNneuenRechts,
  ] = React.useState(false);

  const [form_niederlassung, setForm_niederlassung] = useState("");

  const [iDSelectedCourt, setIDSelectedCourt] = useState<ICourt>();

  const [form_registernummer, setForm_registernummer] = useState("");

  const [form_postleitzahl, setForm_postleitzahl] = useState("");
  const [form_ort, setForm_ort] = useState("");
  const [form_strasse, setForm_strasse] = useState("");

  const [checkedStates, setCheckedStates] = useState<CheckedStates>(
    bundeslaender.reduce((acc, land) => ({ ...acc, [land]: false }), {})
  );

  const [registerArt, setRegisterArt] = useState(
    props.registerArt !== undefined ? props.registerArt : "alle"
  );
  const [isCheckedStatesAllowed, setIsCheckedStatesAllowed] = useState(true);
  const [selectedRechtsform, setSelectedRechtsform] = useState<string>("");

  type CheckedStates = {
    [key: string]: boolean;
  };

  const handleChangeRechtsform = (event: SelectChangeEvent<string>) => {
    setSelectedRechtsform(event.target.value as string);
  };

  useEffect(() => {
    let trueCount = Object.values(checkedStates).filter(
      (value) => value
    ).length;
    if (trueCount > 2) {
      setIsCheckedStatesAllowed(false);
    } else {
      setIsCheckedStatesAllowed(true);
    }
  }, [checkedStates]);

  {
    /*
    const handleChangeTradeRegister = (event: any) => {
        setIDSelectedCourt(event.target.value);
    };

    const filteredCourts = tradeRegisterArray
        .filter((court: IDistrictCourt) =>
            court.Court.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a.Court.localeCompare(b.Court));
    */
  }

  const handleChangeRegisterArt = (event: SelectChangeEvent<string>) => {
    setRegisterArt(event.target.value as string);
  };

  const handleChangeBundeslaender = (event: {
    target: { name: any; checked: any };
  }) => {
    setCheckedStates({
      ...checkedStates,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    switch (name) {
      case "Aehnlich":
        setCheckedSuchOptionenAehnlich(checked);
        break;
      case "Geloescht":
        //setCheckedSuchOptionenGeloescht(checked);
        break;
      case "NurZNneuenRechts":
        setCheckedSuchOptionenNurZNneuenRechts(checked);
        break;
      default:
        break;
    }
  };

  const handleChangeSchlagwortOptionen = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSchlagwortOptionen(Number((event.target as HTMLInputElement).value));
  };

  const handleClose = () => {
    props.setOpenImportFromHandelsregister(false);
  };

  const setShowMessages = (
    showNotFound: boolean,
    showTimeout: boolean,
    showImportSuccessfull: boolean
  ) => {
    setShowNotFoundMessage(showNotFound);
    setShowTimeoutMessage(showTimeout);
    setShowImportSuccesfullMessage(showImportSuccessfull);
  };
  const timeoutFunction = () => {
    async function tryTimeoutFunction() {
      setTimeout(() => {
        if (showImportSuccesfullMessage && showNotFoundMessage) {
          setShowMessages(false, true, false);
        }
        setIsLoading(false);
      }, 60000);
    }

    tryTimeoutFunction();
  };

  const searchFromHandelsregister = () => {
    timeoutFunction();
    setCompanyName(searchString);

    async function trySearchCompanyFormationFromHandelsregister() {
      const payload = {
        searchString: searchString,
        schlagwortOptionen: schlagwortOptionen,
        suchOptionen: {
          aehnlich: checkedSuchOptionenAehnlich,
          geloescht: checkedSuchOptionenGeloescht,
          nurZNneuenRechts: checkedSuchOptionenNurZNneuenRechts,
        },
        niederlassung: form_niederlassung,
        checkedStates: checkedStates,
        registerArt: registerArt,
        form_registernummer: form_registernummer,
        iDSelectedCourt: iDSelectedCourt,
        selectedRechtsform: selectedRechtsform,
        form_postleitzahl: form_postleitzahl,
        form_ort: form_ort,
        form_strasse: form_strasse,
      };
      await fetch("/api/companyformation/searchCompanyFromHandelsregister", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status === 200) {
            setShowMessages(false, false, false);
            return res.json();
          }
          if (res.status === 204) {
            setIsLoading(false);
            setShowMessages(true, false, false);
            return res.json();
          }
        })
        .then((res) => {
          setShowNotFoundMessage(false);
          setShowTimeoutMessage(false);
          setIsLoading(false);
          setHandelsreisterSearchRes(res);
        })
        .catch((error) => {});
    }

    trySearchCompanyFormationFromHandelsregister();
  };

  const getCompanyFormationFromHandelsregister = () => {
    timeoutFunction();
    setCompanyName(searchString);

    async function tryGetCompanyFormationFromHandelsregister() {
      await fetch(
        "/api/companyformation/getCompanyFromHandelsregister/" +
          selectedSearch.name,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            setShowMessages(false, false, true);
            return res.json();
          }
          if (res.status === 204) {
            setIsLoading(false);
            setShowMessages(true, false, false);
            return res.json();
          }
        })
        .then((res) => {
          setShowNotFoundMessage(false);
          setShowTimeoutMessage(false);
          importCompany(res);
          importShareholder(res);
          //props.setWasSuccessfullImportedFromhandelsregister(true)
          setIsLoading(false);
          setShowWasSuccessfully(true);
        })
        .catch((error) => {});
    }

    tryGetCompanyFormationFromHandelsregister();
  };

  const importCompany = (res: any) => {
    setCompanyName(res["CompanyName"]);
    let temp = {
      ...props.companyFormationObject,
      CompanyName: res["CompanyName"],
      DateOfFormation: res["DateOfFormation"],
      PurposeOfTheCompany: res["PurposeOfTheCompany"],
      ShareCapital: Number(res["ShareCapital"]),
      Street: res["Street"],
      StreetNr: res["StreetNr"],
      AmountShares: 1,
      ValuePerShare: 25000,
      useSampleProtocol: false,
      Remarks: "",
      idPostcode: res["idPostcode"],
      Postcode: res["Postcode"],
      City: res["Ort"],
    };

    props.setCompanyFormationObject({
      ...temp,
    } as ICompanyFormation);
  };

  const importShareholder = (res: any) => {
    const beteiligte = res["Beteiligte"];
    let tempShareHolderArray: IShareHolderFullObject[] = [];
    let shareHolderRelationArray: ICompanyFormationHasShareHolder[] = [];
    let idShareholder = -1;

    let companyObject = {
      Company: "",
      RegisterCourt: null,
      RegisterNumber: null,
      SalesTaxNumber: null,
      Street: "",
      StreetAditional: "",
      StreetNr: "",
      TaxNumber: null,
      idBusinessForm: 1,
      idCompany: -1,
      idPostcode: -1,
    } as ICompany;

    beteiligte.forEach((x: any) => {
      let tempShareHolder = {
        idShareHolder: idShareholder,
        idGender: x["idGender"],
        idAcademicTitle: 1,
        idBusinessForm: 1,
        FirstName: x["Vorname"],
        LastName: x["Nachname"],
        Birthday: x["Geburtsdatum"],
        Birthplace: null,
        Street: "",
        StreetNr: "",
        StreetAditional: "",
        idPostcode: x["idPostcode"],
        Company: null,
        idCompany: null,
        //Shares: number;
        Deposit: 0,
        isPartPayment: false,
        isShareByNonCashResources: false,
        Shares: 1,
        //  Aus Relationen
        Postcode: x["Postcode"],
        City: x["Ort"],
        CompanyObject: companyObject,
      } as IShareHolderFullObject;

      let tempShareHolderObject = {
        idCompanyFormation: -1,
        idShareHolder: idShareholder,
        idShareHolderType: 1,
        idCompanyFormationHasShareHolder: -1,
      } as ICompanyFormationHasShareHolder;

      shareHolderRelationArray.push(tempShareHolderObject);

      if (x["Rolle"] !== "1") {
        let tempShareHolderObject = {
          idCompanyFormation: -1,
          idShareHolder: idShareholder,
          idCompanyFormationHasShareHolder: -1,
          idShareHolderType: Number(x["Rolle"]),
        } as ICompanyFormationHasShareHolder;
        shareHolderRelationArray.push(tempShareHolderObject);
      }

      tempShareHolderArray.push(tempShareHolder);

      idShareholder = idShareholder - 1;
    });
    props.setShareHolderArray(tempShareHolderArray as IShareHolderFullObject[]);
  };

  return (
    <>
      <DialogResponsive
        title="Informationen aus dem jeweiligen Register importieren"
        open={props.openImportFromHandelsregister}
        onClose={handleClose}
      >
        {isLoading ? (
          <>
            <DialogContentText>Bitte warten</DialogContentText>
            <CustomCircularProgress></CustomCircularProgress>
          </>
        ) : showWasSuccessfully ? (
          <>
            <Alert severity="success">
              Die Gesellschaft <i>{selectedSearch.name}</i> konnte erfolgreich
              gefunden und importiert werden.
            </Alert>

            <Button
              sx={{ float: "right", mt: 2 }}
              variant="contained"
              onClick={() =>
                props.setWasSuccessfullImportedFromhandelsregister(true)
              }
            >
              Okay
            </Button>
          </>
        ) : handelsreisterSearchRes != undefined ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 100 }}>Auswählen</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Amtsgericht</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {handelsreisterSearchRes.map((x) => (
                  <TableRow>
                    <TableCell onClick={() => setSelectedSearch(x)}>
                      <Radio checked={x.name === selectedSearch.name} />
                    </TableCell>
                    <TableCell>{x.name}</TableCell>
                    <TableCell>{x.court}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ mt: 1, display: "flex", flexDirection: "row" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setSearchString("");
                  setIsLoading(false);
                  setShowNotFoundMessage(false);
                  setShowTimeoutMessage(false);
                  setHandelsreisterSearchRes(null);
                }}
              >
                Zurück
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                variant="contained"
                disabled={selectedSearch.name === ""}
                onClick={() => {
                  getCompanyFormationFromHandelsregister();
                  setShowNotFoundMessage(false);
                  setShowTimeoutMessage(false);
                  setIsLoading(true);
                }}
              >
                Importieren
              </Button>
            </Box>
          </>
        ) : (
          <>
            {showNotFoundMessage && (
              <Alert variant="filled" severity="info">
                Es wurde keine Gesellschaft "{companyName}" gefunden
              </Alert>
            )}
            {showTimeoutMessage && (
              <Alert variant="filled" severity="warning">
                Die Anfrage hat zu lange gedauert
              </Alert>
            )}
            {showImportSuccesfullMessage && (
              <Alert variant="filled" severity="success">
                Informationen von "{companyName}" erfolgreich Importiert
              </Alert>
            )}

            <Grid container spacing={1}>
              {!isCheckedStatesAllowed && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    Bitte wählen Sie maximal 2 Bundesländer aus.
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  id="searchString"
                  label={props.searchLabel}
                  value={searchString}
                  fullWidth
                  required
                  variant="outlined"
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography>Suche nach Einträgen, die</Typography>
                </Grid>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={schlagwortOptionen}
                    onChange={handleChangeSchlagwortOptionen}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="alle Schlagwörter enthalten"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="mindestens ein Schlagwort enthalten"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="den genauen Namen der Gesellschaft enthalten"
                    />
                  </RadioGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Aehnlich"
                        checked={checkedSuchOptionenAehnlich}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="ähnlich lautende Schlagwörter enthalten."
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDown />}
                    aria-controls="max-search-content"
                    id="max-search"
                  >
                    <Typography>Erweiterte Suche</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDropDown />}
                            aria-controls="bundeslaender-content"
                            id="bundeslaender"
                          >
                            <Typography>Bundesländer</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              {bundeslaender.map((land, index) => (
                                <Grid item xs={6} key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={checkedStates[land]}
                                        onChange={handleChangeBundeslaender}
                                        name={land}
                                      />
                                    }
                                    label={land}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>

                      <Grid item xs={12}>
                        Niederlassung / Sitz:
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          fullWidth
                          margin="dense"
                          id="niederlassung"
                          label={"Niederlassung"}
                          value={form_niederlassung}
                          variant="outlined"
                          size="small"
                          onChange={(e) => {
                            setForm_niederlassung(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        Zusätzliche Suchoptionen:
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="NurZNneuenRechts"
                              checked={checkedSuchOptionenNurZNneuenRechts}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="Nur nach Zweigniederlassungen neuen Rechts suchen."
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDropDown />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              Angaben nur zur Hauptniederlassung:
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography>Aktenzeichen:</Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="registerArt-label">
                                    Registerart
                                  </InputLabel>
                                  <Select
                                    labelId="registerArt-label"
                                    value={registerArt}
                                    onChange={handleChangeRegisterArt}
                                    label="Registerart"
                                    size="small"
                                  >
                                    {registerArten.map((art) => (
                                      <MenuItem key={art} value={art}>
                                        {art}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  autoFocus
                                  fullWidth
                                  margin="dense"
                                  id="Registernummer"
                                  label={"Registernummer:"}
                                  value={form_registernummer}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => {
                                    setForm_registernummer(e.target.value);
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Typography>Adresse:</Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  autoFocus
                                  fullWidth
                                  margin="dense"
                                  id="Postleitzahl"
                                  label={"Postleitzahl:"}
                                  value={form_postleitzahl}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => {
                                    setForm_postleitzahl(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  autoFocus
                                  fullWidth
                                  margin="dense"
                                  id="Ort"
                                  label={"Ort:"}
                                  value={form_ort}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => {
                                    setForm_ort(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  autoFocus
                                  fullWidth
                                  margin="dense"
                                  id="Straße"
                                  label={"Straße:"}
                                  value={form_strasse}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => {
                                    setForm_strasse(e.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Box sx={{ mt: 1, display: "flex", flexDirection: "row" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsLoading(false);
                  setShowNotFoundMessage(false);
                  setShowTimeoutMessage(false);
                  props.setOpenImportFromHandelsregister(false);
                }}
              >
                Abbruch
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                variant="contained"
                disabled={searchString === ""}
                onClick={() => {
                  setSelectedSearch({
                    court: "",
                    name: "",
                    state: "",
                    status: "",
                  });
                  searchFromHandelsregister();
                  setShowNotFoundMessage(false);
                  setShowTimeoutMessage(false);
                  setIsLoading(true);
                }}
              >
                Suche
              </Button>
            </Box>
          </>
        )}
      </DialogResponsive>
    </>
  );
};
