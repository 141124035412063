import React, { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { ICourt } from "../../Interfaces/IAemter";

interface IProps {
  districtCourtObject: ICourt | null | undefined;
  setDistrictCourtObject: Function;
  required?: boolean;
  disabledEdit?: boolean;
  setDistrictCourt: Function;
}

const DistrictCourtSearch: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly ICourt[]>(
    props.districtCourtObject != undefined ? [props.districtCourtObject] : []
  );
  // const loading = open && options.length === 0;
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [districtCourtValue, setDistrictCourtValue] = useState(
    props.districtCourtObject
  );

  async function queryBackend(localSearchTerm: string) {
    setLoading(true);

    if (localSearchTerm === "") {
      setLoading(false);
      setOptions([]);
    } else {
      fetch(`/api/court`, {
        headers: {
          "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw Error(`ERROR GET: ${res.status}`);
          }
        })
        .then((res) => {
          setOptions(res);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  /*async function queryBackend(localSearchTerm: string) {
    setLoading(true);

    if (localSearchTerm === "") {
      setLoading(false);
      setOptions([]);
    } else {
      fetch(`/api/court`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("SessionToken")}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw Error(`ERROR GET: ${res.status}`);
          }
        })
        .then((res) => {
          setOptions(res);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }*/

  function onChange(event: SyntheticEvent, newObject: ICourt | null) {
    setDistrictCourtValue(newObject);
    props.setDistrictCourt(newObject);
    props.setDistrictCourtObject(newObject);
  }

  function onInputChange(event: SyntheticEvent | null, searchTerm: string) {
    if (event !== null && event.type === "change") {
      setLoading(true);
      setSearchTerm(searchTerm);
    }
  }

  const getAmtsgerichtLabel = (amtsgericht: ICourt) => {
    let label = "";
    if (amtsgericht.StreetAditional !== null) {
      label = `${amtsgericht.Court} | ${amtsgericht.Street} ${amtsgericht.StreetNr}  ${amtsgericht.StreetAditional} ${amtsgericht.Postcode} ${amtsgericht.FederalState}`;
    }
    if (amtsgericht.StreetAditional == null) {
      label = `${amtsgericht.Court} |  ${amtsgericht.FederalState}`;
    }
    return label;
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => queryBackend(searchTerm), 500);
    return () => clearTimeout(timeOutId);
  }, [searchTerm]);

  return (
    <Autocomplete
      id="searchbar-districtCourt"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={props.disabledEdit !== undefined && props.disabledEdit === true}
      value={districtCourtValue}
      isOptionEqualToValue={(option, value) =>
        option.idPostcode === value.idPostcode
      }
      getOptionLabel={(option) => getAmtsgerichtLabel(option)}
      loadingText="Laden..."
      options={options}
      loading={loading}
      onInputChange={onInputChange}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Amtsgericht"
          size="small"
          //required={(props.required == undefined) ? true : props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default DistrictCourtSearch;
