import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import ReactPasswordChecklist from "react-password-checklist";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface IProps {
  idLegaltransaction: number;
  dataToSave: any;
  filename: String;
}

export const SaveProgress: React.FC<IProps> = (props) => {
  const isDesktop = useMediaQuery("(min-width:900px)");

  const [openExport, setOpenExport] = React.useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordWasSuccessfull, setPasswordWasSuccessfull] =
    React.useState(false);
  const [isShowPasswordEnabled, setisShowPasswordEnabled] =
    React.useState(false);
  const [wasPasswordGenerated, setwasPasswordGenerated] = React.useState(false);

  const handleClickShowPassword = () =>
    setisShowPasswordEnabled((show) => !show);

  const handleOpenExport = () => {
    setOpenExport(true);
  };
  const handleCloseExport = () => {
    setOpenExport(false);
  };

  const clearPassword = () => {
    setPassword("");
    setPasswordAgain("");
    setisShowPasswordEnabled(false);
    setwasPasswordGenerated(false);
  };

  const checkPasswordValidity = (
    value: string,
    enablePwCheck: boolean = true
  ) => {
    const nonWhiteSpace = /^\S*$/;
    if (!nonWhiteSpace.test(value)) {
      return false;
    }

    const containsUppercase = /^(?=.*[A-Z]).*$/;
    if (!containsUppercase.test(value)) {
      return false;
    }

    const containsLowercase = /^(?=.*[a-z]).*$/;
    if (!containsLowercase.test(value)) {
      return false;
    }

    const containsNumber = /^(?=.*[0-9]).*$/;
    if (!containsNumber.test(value)) {
      return false;
    }

    const containsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    if (!containsSymbol.test(value)) {
      return false;
    }

    const validLength = /^.{12,32}$/;
    if (!validLength.test(value)) {
      return false;
    }
    if (enablePwCheck && password != passwordAgain) {
      return false;
    }
    return true;
  };

  function checkAndGetRandomPassword() {
    let result = generateRandomPassword();
    while (!checkPasswordValidity(result, false)) {
      result = generateRandomPassword();
    }
    setPassword(result);
    setPasswordAgain(result);
    setisShowPasswordEnabled(true);
    setwasPasswordGenerated(true);
  }

  function generateRandomPassword() {
    let result = "";
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!"§$%&/()=?+-*#';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 16) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const downloadJson = () => {
    if (passwordWasSuccessfull) {
      var CryptoJS = require("crypto-js");
      const element = document.createElement("a");
      const textFile = new Blob(
        [CryptoJS.AES.encrypt(JSON.stringify(props.dataToSave), password)],
        { type: "text/plain" }
      ); //pass data from localStorage API to blob
      element.href = URL.createObjectURL(textFile);
      element.download = `${props.filename}.notar`;
      document.body.appendChild(element);
      element.click();
    }
  };

  const antiEventBubbling = (event: any) => {
    event.stopPropagation(); // Unterbinde Event Bubbling
  };

  const handleClickOpen = (event: any) => {
    event.stopPropagation(); // Unterbinde Event Bubbling
    clearPassword();
    handleOpenExport();
  };

  const copyPasswordToClipboard = () => {
    navigator.clipboard.writeText(password);
  };

  return (
    <>
      {isDesktop ? (
        <Button
          key="saveProgress-Button"
          color="inherit"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Sichern
        </Button>
      ) : (
        <ListItem key="saveProgress-Button" disablePadding>
          <ListItemButton onClick={handleClickOpen}>
            <ListItemText primary="Sichern" />
          </ListItemButton>
        </ListItem>
      )}

      {/*Informiert den User dass das generierte Passwort manuell gespeichert werden soll*/}
      <Snackbar
        open={wasPasswordGenerated}
        autoHideDuration={6000}
        onClose={() => setwasPasswordGenerated(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setwasPasswordGenerated(false)}
          severity={"info"}
          sx={{ width: "100%" }}
        >
          Bitte speichern Sie das generierte Passwort sicher ab.
        </Alert>
      </Snackbar>

      <Dialog
        open={openExport}
        onClose={handleCloseExport}
        onClick={antiEventBubbling}
      >
        <DialogTitle variant="h4">Exportieren</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Typography>
              Bitte geben Sie ein Passwort zum Verschlüsseln der Datei ein.
            </Typography>

            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Passwort"
              type={isShowPasswordEnabled ? "string" : "password"}
              value={password}
              fullWidth
              required
              variant="standard"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {!isShowPasswordEnabled ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mt={2}>
            <Typography>Bitte geben Sie das Passwort erneut ein.</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Passwort"
              type={isShowPasswordEnabled ? "string" : "password"}
              value={passwordAgain}
              fullWidth
              required
              variant="standard"
              onChange={(e) => {
                setPasswordAgain(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {!isShowPasswordEnabled ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={4}>
            <Button
              onClick={() => {
                checkAndGetRandomPassword();
              }}
            >
              Passwort generieren
            </Button>

            <ReactPasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={12}
              value={password}
              valueAgain={passwordAgain}
              onChange={(passwordWasSuccessfull) =>
                setPasswordWasSuccessfull(passwordWasSuccessfull)
              }
              messages={{
                minLength: "Das Passwort hat 12 Zeichen.",
                specialChar: "Das Passwort hat ein Sonderzeichen.",
                number: "Das Passwort hat eine Zahl.",
                capital: "Das Passwort hat ein Großbuchstaben.",
                match: "Die Passwörter stimmen überein",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseExport();
              clearPassword();
            }}
          >
            Abbrechen
          </Button>
          <Button
            disabled={!passwordWasSuccessfull}
            onClick={() => {
              handleCloseExport();
              downloadJson();
              clearPassword();
              copyPasswordToClipboard(); // Passwort wird hier in die Zwischenablage kopiert
            }}
          >
            Exportieren
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
