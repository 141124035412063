import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFetch";
import {
  Alert,
  Box,
  Button,
  Fade,
  Step,
  StepLabel,
} from "@mui/material";
import { CustomCircularProgress } from "../../generic/CustomCircularProgress";
import { CustomeSlide } from "../../core/CustomeSlide";
import Send from "../../generic/Send";
import { IConfig } from "../../../Interfaces/IConfig";
import { DialogFrame } from "../../core/DialogFrame";

interface IProps {
  configObject: IConfig;
}

export const ResetPasswort: React.FC<IProps> = ({ configObject }) => {
  const params = useParams();
  const [tokenValide, setTokenValide, wasSuccessfully] = useFetch<{
    Text: string | null;
  }>(`/contact/requestNewPassword/${params.idContact}/${params.token}`);
  //
  const [currentPage, setCurrentPage] = useState(0);
  //
  const [isConfirmed, setIsConfirmed] = useState<null | boolean>(null);
  //
  const [isLoading, setIsLoading] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [wasSuccessfullyUploaded, setWasSuccessfullyUploaded] = useState(true);

  const contextTSx = () => {
    if (!wasSuccessfully) {
      return (
        <>
          <Alert severity="warning">
            Das Passwort kann nicht zurückgesetzt werden oder die Anfrage ist
            bereits abgelaufen.
            <br />
            In dringenden Fällen kontaktieren Sie uns bitte.
          </Alert>
        </>
      );
    } else if (!wasSuccessfullyUploaded) {
      return (
        <>
          <Alert severity="error">
            Ihre Antwort konnte nicht hochgeladen werden.
            <br />
            In dringenden Fällen kontaktieren Sie uns bitte.
          </Alert>
        </>
      );
    } else if (isLoading || tokenValide === undefined) {
      return (
        <>
          <CustomCircularProgress />
        </>
      );
    } else {
      return (
        <>
          <CustomeSlide
            currentPage={currentPage}
            pageNumber={0}
            lastCurrentPage={-1}
            appear={false}
          >
            <>
              <Alert severity="success">
                Ihr Passwort wurden erfolgreich zurückgesetzt!
              </Alert>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Fade in={isConfirmed !== null}>
                  <Button variant="contained" onClick={() => {}}>
                    Absenden
                  </Button>
                </Fade>
              </Box>
            </>
          </CustomeSlide>

          <CustomeSlide
            currentPage={currentPage}
            pageNumber={1}
            lastCurrentPage={0}
          >
            <Send />
          </CustomeSlide>
        </>
      );
    }
  };

  return (
    <>
      <DialogFrame
        configObject={configObject}
        title="Passwort zurücksetzen"
        currentPage={currentPage}
        disableHorizontalWarning
        stepperArray={[
          <Step key="invite0" completed={currentPage > 0}>
            <StepLabel>Vorgang bestätigen</StepLabel>
          </Step>,
        ]}
      >
        <>{contextTSx()}</>
      </DialogFrame>
    </>
  );
};
