import { IPurchaseContractRelationType } from "../Interfaces/IPurchaseContractRelationType";

export const purchaseContractRelationTypeArray: IPurchaseContractRelationType[] =
  [
    {
      idPurchaseContractRelationType: 1,
      PurchaseContractRelationType: "Verkäufer/in",
    },
    {
      idPurchaseContractRelationType: 5,
      PurchaseContractRelationType: "Käufer/in",
    },
  ];
