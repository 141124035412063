import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Collapse,
  Alert,
  AlertTitle,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import { checkTranslation, getBirthday } from "../core/standardFunctions";
import { ICompanyFormation } from "../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../Interfaces/ICompanyFormationHasShareHolder";
import { ShareHolderEdit } from "../public/Dialog/Founding/Childs/ShareHolder/ShareHolderEdit";
import { SelectShareHolderDialog } from "../public/Dialog/Founding/Childs/gmbh/Shares/SelectShareHolderDialog";
import { useTranslation } from "react-i18next";

interface IProps {
  idShareHolderType: number;
  titleTablePerson: string;
  disablePersonSelectCompanyType: boolean;
  minShareHolder: number;
  warningText: string;

  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  companyFormationShareArray: ICompanyFormationShare[];
  setCompanyFormationShareArray: Function;
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;

  isTranslationEnabled?: boolean;
}

export const ShareholderWithoutShares: React.FC<IProps> = (props) => {
  const handleDelete = (id: number) => {
    props.setShareHolderArray(
      props.shareHolderArray.filter((x) => x.idShareHolder !== id)
    );
  };
  const handleClose = () => {
    setIdShareHolder(null);
  };
  const { t } = useTranslation();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [hasSoftWarning, setHasSoftWarning] = useState(false);

  useEffect(() => {
    if (
      props.minShareHolder !== 0 &&
      props.shareHolderArray.length < props.minShareHolder
    ) {
      setHasSoftWarning(true);
    } else {
      setHasSoftWarning(false);
    }
  }, [props.shareHolderArray.length]);

  const [idShareHolder, setIdShareHolder] = useState<number | null>(null);
  return (
    <>
      <Collapse in={hasSoftWarning}>
        <Alert severity="warning">
          <AlertTitle>{t("warning")}</AlertTitle>
          {props.warningText}
        </Alert>
      </Collapse>

      <Dialog
        open={idShareHolder !== null}
        onClose={() => setIdShareHolder(null)}
        maxWidth="md"
        fullWidth
      >
        {idShareHolder !== null && (
          <>
            <DialogTitle>
              {checkTranslation(t, "edit_person", "Person bearbeiten")}
            </DialogTitle>
            <Box sx={{ mt: 2 }}>
              <DialogContent>
                <ShareHolderEdit
                  idShareHolder={idShareHolder}
                  handleClose={handleClose}
                  shareHolderArray={props.shareHolderArray}
                  setShareHolderArray={props.setShareHolderArray}
                  disableSelectCompanyType={
                    props.disablePersonSelectCompanyType
                  }
                  isTranslationEnabled={props.isTranslationEnabled}
                />
              </DialogContent>
            </Box>
          </>
        )}
      </Dialog>

      <SelectShareHolderDialog
        idShareHolderType={props.idShareHolderType}
        shareHolderArray={props.shareHolderArray}
        setShareHolderArray={props.setShareHolderArray}
        shareHolderRelation={props.shareHolderRelation}
        setShareHolderRelation={props.setShareHolderRelation}
        //
        companyFormationObject={props.companyFormationObject}
        companyFormationShareArray={props.companyFormationShareArray}
        setCompanyFormationShareArray={props.setCompanyFormationShareArray}
        //
        disableSelectCompanyType={props.disablePersonSelectCompanyType}
      />

      <Table sx={{ mt: 5 }}>
        <TableHead>
          <TableRow>
            <TableCell>{props.titleTablePerson}</TableCell>
            <TableCell>
              {checkTranslation(
                t,
                "input_ra_board_table_birthday",
                "Geburtsdatum"
              )}
            </TableCell>
            <TableCell>
              {checkTranslation(t, "input_ra_board_table_address", "Adresse")}
            </TableCell>
            <TableCell>
              {checkTranslation(t, "input_ra_board_table_actions", "Aktionen")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.shareHolderArray.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>
                <i>
                  {checkTranslation(
                    t,
                    "board_ra_table_empty",
                    "Keine Personen vorhanden..."
                  )}{" "}
                  {props.titleTablePerson}{" "}
                </i>
              </TableCell>
            </TableRow>
          )}
          {props.shareHolderArray.map((shareholder) => (
            <TableRow
              key={"shar"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {shareholder.idBusinessForm === 1 ? (
                  <>
                    {shareholder.FirstName} {shareholder.LastName}
                  </>
                ) : (
                  <>{shareholder.CompanyObject.Company}</>
                )}
              </TableCell>

              <TableCell component="th" scope="row">
                {getBirthday(shareholder)}
              </TableCell>
              <TableCell component="th" scope="row">
                {shareholder.idBusinessForm === 1 ? (
                  <>
                    {" "}
                    {shareholder.Street} {shareholder.StreetNr}{" "}
                    {shareholder.City} {shareholder.Postcode}
                  </>
                ) : (
                  <>
                    {" "}
                    {shareholder.CompanyObject.Street}{" "}
                    {shareholder.CompanyObject.StreetNr}{" "}
                    {shareholder.CompanyObject.City}{" "}
                    {shareholder.CompanyObject.Postcode}
                  </>
                )}
              </TableCell>
              <TableCell sx={{ width: 90 }}>
                <IconButton
                  onClick={() => {
                    {
                      setIdShareHolder(Number(shareholder.idShareHolder));
                    }
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    {
                      props.setShareHolderRelation(
                        props.shareHolderRelation.filter(
                          (x) => x.idShareHolder !== shareholder.idShareHolder
                        )
                      );
                      props.setShareHolderArray(
                        props.shareHolderArray.filter(
                          (x) => x.idShareHolder !== shareholder.idShareHolder
                        )
                      );
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
