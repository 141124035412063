import { ICompanyFormationShare } from "../../../../Interfaces/ICompanyFormationShare";
import {
  ICompanyFormation,
} from "../../../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../../../Interfaces/IShareHolder";
import { ICompanyFormationHasShareHolder } from "../../../../Interfaces/ICompanyFormationHasShareHolder";
import { ICompanyFormationAnswer } from "../../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../../Interfaces/ICompanyFormationQuestion";
import { ICompanyFormationOption } from "../../../../Interfaces/ICompanyFormationOption";
import { IDocument } from "../../../../Interfaces/IDocument";
import React from "react";
import { IPostcode } from "../../../../Interfaces/IPostcode";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { checkString } from "../../../core/standardFunctions";
import { CompanyFormationAnswerSummary } from "../Founding/Childs/Summary/CompanyFormationAnswerSummary";
import { academicTitleArray } from "../../../../StaticArraies/academicTitleArray";
import { genderArray } from "../../../../StaticArraies/genderArray";
import { Search } from "@mui/icons-material";

export interface IProps {
  companyFormationObject: ICompanyFormation;
  shareHolderArray: IShareHolderFullObject[];
  companyFormationShareArray: ICompanyFormationShare[];
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  companyFormationQuestionArray: ICompanyFormationQuestion[];
  companyFormationOptionArray: ICompanyFormationOption[];
  documentArray?: IDocument[];
  postCodeObject: IPostcode | undefined;
  addressPostCodeObject: IPostcode | undefined;

  setIdShareHolder: Function;
}

export const RAChangeSummaryComparison: React.FC<IProps> = (props) => {
  return (
    <>
      <Grid container sx={{ pl: 10 }} spacing={2}>
        <Grid item sm={4}>
          <b>Vereinsname:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.CompanyName)}
        </Grid>

        <Grid item sm={4}>
          <b>Vereinssitz:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.Street)}{" "}
          {checkString(props.companyFormationObject.StreetNr)}
        </Grid>

        <Grid item sm={4}></Grid>
        <Grid item sm={8}>
          {props.postCodeObject === undefined ? (
            <>k.A.</>
          ) : (
            <>
              {checkString(props.postCodeObject.Postcode)}{" "}
              {checkString(props.postCodeObject.City)}
            </>
          )}
        </Grid>

        {(props.companyFormationObject.AddressStreet !== null ||
          props.companyFormationObject.AddressStreetNr !== null ||
          props.companyFormationObject.Postcode_AddressidPostcode !== null) && (
          <>
            <Grid item sm={4}>
              <b>Vereinsanschrift:</b>
            </Grid>
            <Grid item sm={8}>
              {checkString(props.companyFormationObject.AddressStreet)}{" "}
              {checkString(props.companyFormationObject.AddressStreetNr)}
            </Grid>

            <Grid item sm={4}></Grid>
            <Grid item sm={8}>
              {props.addressPostCodeObject === undefined ? (
                <>k.A.</>
              ) : (
                <>
                  {checkString(props.addressPostCodeObject.Postcode)}{" "}
                  {checkString(props.addressPostCodeObject.City)}
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <CompanyFormationAnswerSummary
        companyFormationAnswerArray={props.companyFormationAnswerArray}
        companyFormationOptionArray={props.companyFormationOptionArray}
        companyFormationQuestionArray={props.companyFormationQuestionArray}
      />

      <Box sx={{ mt: 10 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Vorstand
      </Typography>
      <Typography>
        Der Vorstand im Sinne des §26 BGB besteht aus{" "}
        {props.shareHolderRelation.length === 1
          ? `dem ${props.shareHolderRelation[0].FreeText}.`
          : props.shareHolderRelation.map((x, i) =>
              i + 1 === props.shareHolderRelation.length
                ? `dem ${x.FreeText}.`
                : `dem ${x.FreeText}, `
            )}
      </Typography>

      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Funktion</TableCell>
            <TableCell>Titel</TableCell>
            <TableCell>Geschlecht</TableCell>
            <TableCell>Vorname</TableCell>
            <TableCell>Nachname</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.shareHolderArray.map((x) => (
            <TableRow>
              <TableCell sx={{ color: "black" }}>
                {props.shareHolderRelation
                  .filter((y) => y.idShareHolder === x.idShareHolder)
                  .map((x) => x.FreeText)
                  .join(", ")}
              </TableCell>
              <TableCell>
                {x.idAcademicTitle === 1
                  ? ""
                  : checkString(
                      academicTitleArray.find(
                        (y) => y.idAcademicTitle === x.idAcademicTitle
                      )?.AcademicTitle
                    )}
              </TableCell>
              <TableCell>
                {checkString(
                  genderArray.find((y) => y.idGender === x.idGender)?.Gender
                )}
              </TableCell>

              <TableCell>{x.FirstName}</TableCell>
              <TableCell>{x.LastName}</TableCell>

              <TableCell>
                <IconButton
                  onClick={() => props.setIdShareHolder(x.idShareHolder)}
                >
                  <Search />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
