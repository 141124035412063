import React from "react";
import { IRealEstateHasInventoryOption } from "../../../../../../Interfaces/IRealEstateHasInventoryOption";
import { IInventoryOption } from "../../../../../../Interfaces/IInventoryOption";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AddRealEstateInventoryOption } from "../InventoryOption/AddRealEstateInventoryOption";
import { RealEstateInventoryOptionRow } from "../InventoryOption/RealEstateInventoryOptionRow";

interface IProps {
  idRealEstate: number;
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setRealEstateHasInventroyOptionArray: Function;
  inventoryOptionArray: IInventoryOption[];
  marginTop?: number;
}

export const RealEstateInventoryOptions: React.FC<IProps> = (props) => {
  return (
    <>
      <Typography
        variant="h5"
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 1 }}
      >
        Inventar und Anlagen
        <br />
        <Typography>
          Zusammen mit dem Grundstück oder dem Erbbaurecht können auch{" "}
          <u>Inventar und/oder Anlagen</u> verkauft werden. Bitte erfassen Sie
          das Inventar (z.B. eine Einbauküche oder Möbel) bzw. die Anlagen (z.B.
          eine Fotovoltaikanlage oder ein Bootssteg), die mitverkauft werden
          sollen.
        </Typography>
      </Typography>

      <AddRealEstateInventoryOption
        idRealEstate={props.idRealEstate}
        inventoryOptionArray={props.inventoryOptionArray}
        realEstateHasInventroyOptionArray={
          props.realEstateHasInventroyOptionArray
        }
        setRealEstateHasInventroyOptionArray={
          props.setRealEstateHasInventroyOptionArray
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Anzahl</TableCell>
            <TableCell>Bezeichnung</TableCell>
            <TableCell>Beschreibung</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.realEstateHasInventroyOptionArray.map((x) => (
            <RealEstateInventoryOptionRow
              key={`idInventoryOption-${x.idInventoryOption}`}
              currentObject={x}
              inventoryOptionArray={props.inventoryOptionArray}
              realEstateHasInventroyOptionArray={
                props.realEstateHasInventroyOptionArray
              }
              setRealEstateHasInventroyOptionArray={
                props.setRealEstateHasInventroyOptionArray
              }
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};
