import React, { useEffect, useState } from "react";
import { IDocument } from "../../../../../Interfaces/IDocument";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DocumentOverview } from "../../../../Document/DocumentOverview";
import { ICompanyFormationAnswer } from "../../../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../../../Interfaces/ICompanyFormationQuestion";
import { ICompanyFormationOption } from "../../../../../Interfaces/ICompanyFormationOption";
import { getCompanyFormationAnswer } from "../../Founding/companyFormation_helpers";
import { addUpdateAnswerArray } from "./RARepresentative";
import { useTranslation } from "react-i18next";
import { checkTranslation } from "../../../../core/standardFunctions";

interface IProps {
  handleBack: Function;
  handleNext: Function;
  documentArray: IDocument[];
  setDocumentArray: Function;
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  setCompanyFormationAnswerArray: Function;
  companyFormationQuestionArray: ICompanyFormationQuestion[];
  companyFormationOptionArray: ICompanyFormationOption[];

  //Für CompanyChange Buttons deaktiveren
  buttonsDisabled?: boolean;
}

export const RAConstitution: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [companyAnswerDateOfConsitution, setCompanyAnswerDateOfConsitution] =
    useState(
      getCompanyFormationAnswer(9, props.companyFormationAnswerArray, 28)
    );

  useEffect(() => {
    props.setCompanyFormationAnswerArray(
      addUpdateAnswerArray(
        props.companyFormationAnswerArray,
        companyAnswerDateOfConsitution
      )
    );
  }, [companyAnswerDateOfConsitution]);

  return (
    <>
      <Box sx={{ mt: 5 }} />
      <Typography variant="h4" sx={{ mb: 3 }}>
        {checkTranslation(t, "association_articles", "Satzung des Vereins")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6">
            {checkTranslation(t, "association_articles_date", "Satzungsdatum")}
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <TextField
            type="date"
            label={checkTranslation(
              t,
              "association_articles_date",
              "Satzungsdatum"
            )}
            fullWidth
            size="small"
            error={companyAnswerDateOfConsitution.TextValue === null}
            value={
              companyAnswerDateOfConsitution.TextValue === null
                ? "1"
                : companyAnswerDateOfConsitution.TextValue
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyAnswerDateOfConsitution({
                ...companyAnswerDateOfConsitution,
                TextValue:
                  event.target.value === "" ? null : event.target.value,
              });
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ mt: 10 }}>
        {checkTranslation(
          t,
          "association_articles_documents",
          "Satzungs- und Gründungsdokumente"
        )}
      </Typography>
      <Typography sx={{ mb: 3 }} component={"span"} variant={"body2"}>
        <u>
          {checkTranslation(
            t,
            "association_articles_upload",
            "Bitte laden Sie die Satzung Ihres Vereins hoch."
          )}
        </u>
        <br />
        {checkTranslation(
          t,
          "association_articles_upload_addition",
          "Ergänzend können Sie weitere Dokumente aus dem Gründungsprozess hochladen, um eine schnelle Bearbeitung sicherzustellen."
        )}

        {/*
                <br/>{t("association_articles_upload_addition_list")}

                <ul>
                    <li>{t("member_overview")}</li>
                    <li>{t("founding_protocol")}</li>
                    <li>{t("tax_exempt_status")}</li>
                </ul>
                */}
      </Typography>
      <DocumentOverview
        allowRemove
        allowUpload
        documentArray={props.documentArray}
        setDocumentArray={props.setDocumentArray}
        title=""
      />
      {(props.buttonsDisabled === undefined ||
        props.buttonsDisabled === false) && (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 10 }}>
          <Button
            variant="outlined"
            onClick={() => props.handleBack()}
            sx={{ mr: 1 }}
          >
            {checkTranslation(t, "button_back", "Zurück")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            disabled={companyAnswerDateOfConsitution.TextValue === null}
            onClick={() => props.handleNext()}
          >
            {checkTranslation(t, "button_next", "Weiter")}
          </Button>
        </Box>
      )}
    </>
  );
};
