import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { IPostcode } from "../../../../../../../Interfaces/IPostcode";
import { ICompany } from "../../../../../../../Interfaces/ICompany";
import PostcodeSearch from "../../../../../../generic/PostcodeSearch";

interface IProps {
  companyObject: ICompany;
  setCompanyObject: Function;
  isImportedFromHandelsregister?: boolean;
}

const handlePostcode = (localObject: ICompany) => {
  if (
    localObject.idPostcode != null &&
    localObject.idPostcode !== null &&
    localObject.idPostcode > 0 &&
    localObject.City != undefined &&
    localObject.Postcode != undefined
  ) {
    return {
      idPostcode: localObject.idPostcode,
      City: localObject.City,
      Postcode: localObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

const CompanyModifiedBaseInformation: React.FC<IProps> = (props) => {
  const [postcodeObjectCompany, setPostcodeObjectCompany] = useState<
    IPostcode | null | undefined
  >(handlePostcode(props.companyObject));

  const setPostcodeObjectCompanyWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      props.setCompanyObject({
        ...props.companyObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as ICompany);
    } else {
      props.setCompanyObject({
        ...props.companyObject,
        idPostcode: null,
        Postcode: undefined,
        City: undefined,
      } as ICompany);
    }
    setPostcodeObjectCompany(postcodeObject);
  };

  return (
    <>
      <Box sx={{ mt: 6 }} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        Firmendaten
      </Typography>
      <Grid container spacing={2}></Grid>
      <Box sx={{ mt: 6 }} />
      <Typography sx={{ mt: 2, mb: 1 }}>{"Firma"}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={"Firma"}
            size="small"
            required
            fullWidth
            value={props.companyObject.Company}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCompanyObject({
                ...props.companyObject,
                Company: event.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Handelsregisternummer"
            size="small"
            fullWidth
            value={
              props.companyObject.RegisterNumber === null
                ? ""
                : props.companyObject.RegisterNumber
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCompanyObject({
                ...props.companyObject,
                RegisterNumber:
                  event.target.value === "" ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label="Handelsregistergericht"
            size="small"
            fullWidth
            value={
              props.companyObject.RegisterCourt === null
                ? ""
                : props.companyObject.RegisterCourt
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCompanyObject({
                ...props.companyObject,
                RegisterCourt:
                  event.target.value === "" ? null : event.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Steuernummer"
            size="small"
            fullWidth
            value={
              props.companyObject.TaxNumber === null
                ? ""
                : props.companyObject.TaxNumber
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCompanyObject({
                ...props.companyObject,
                TaxNumber:
                  event.target.value === "" ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="USt-IdNr."
            size="small"
            fullWidth
            value={
              props.companyObject.SalesTaxNumber === null
                ? ""
                : props.companyObject.SalesTaxNumber
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCompanyObject({
                ...props.companyObject,
                SalesTaxNumber:
                  event.target.value === "" ? null : event.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Typography sx={{ mt: 2, mb: 1 }}>{"Firmensitz"}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            label={"Straße"}
            size="small"
            fullWidth
            value={props.companyObject.Street}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCompanyObject({
                ...props.companyObject,
                Street: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={"Hausnummer"}
            size="small"
            fullWidth
            value={props.companyObject.StreetNr}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCompanyObject({
                ...props.companyObject,
                StreetNr: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PostcodeSearch
            key={postcodeObjectCompany?.idPostcode}
            postcodeObject={postcodeObjectCompany}
            setPostcodeObject={setPostcodeObjectCompanyWrapper}
            required={true}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default CompanyModifiedBaseInformation;
