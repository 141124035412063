import { IBusinessForm } from "../Interfaces/IBusinessForm";

export const businessFormArray: IBusinessForm[] = [
  { idBusinessForm: 1, BusinessForm: "Privat", isLegalEntity: false },
  {
    idBusinessForm: 2,
    BusinessForm: "Einzelunternehmen",
    isLegalEntity: false,
  },
  {
    idBusinessForm: 3,
    BusinessForm: "eingetragene Gesellschaft bürgerlichen Rechts (eGbR)",
    isLegalEntity: false,
  },
  {
    idBusinessForm: 4,
    BusinessForm: "Eingetragener Kaufmann (e.K.)",
    isLegalEntity: false,
  },
  {
    idBusinessForm: 5,
    BusinessForm: "Offene Handelsgesellschaft (OHG)",
    isLegalEntity: false,
  },
  {
    idBusinessForm: 6,
    BusinessForm: "Kommanditgesellschaft (KG)",
    isLegalEntity: false,
  },
  {
    idBusinessForm: 7,
    BusinessForm: "Unternehmergesellschaft (UG)",
    isLegalEntity: true,
  },
  {
    idBusinessForm: 8,
    BusinessForm: "Gesellschaft mit beschränkter Haftung (GmbH)",
    isLegalEntity: true,
  },
  {
    idBusinessForm: 9,
    BusinessForm: "Aktiengesellschaft (AG)",
    isLegalEntity: true,
  },
  { idBusinessForm: 10, BusinessForm: "GmbH & Co KG", isLegalEntity: true },
];
