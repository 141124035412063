import React, { useEffect, useState } from "react";
import {
  IShareHolderFullObject,
} from "../../../../../../Interfaces/IShareHolder";
import { ICompanyFormationHasShareHolder } from "../../../../../../Interfaces/ICompanyFormationHasShareHolder";
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { SelectShareHolderEdit } from "../SelectShareHolderEdit";
import { ShareHolderEdit } from "../ShareHolder/ShareHolderEdit";
import { ICompanyFormationAnswer } from "../../../../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../../../../Interfaces/ICompanyFormationQuestion";
import { ICompanyFormationOption } from "../../../../../../Interfaces/ICompanyFormationOption";
import { getCompanyFormationAnswer } from "../../companyFormation_helpers";
import { useTranslation } from "react-i18next";
import { DialogResponsive } from "../../../../../core/DialogResponsive";
import { checkTranslation } from "../../../../../core/standardFunctions";

interface IProps {
  isCompanyNew: boolean;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  setCompanyFormationAnswerArray: Function;
  companyFormationQuestionArray: ICompanyFormationQuestion[];
  companyFormationOptionArray: ICompanyFormationOption[];
  handleBack: Function;
  handleNext: Function;
}

export const Representation: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [idShareHolder, setIdShareHolder] = useState<number | null>(null);

  const [companyQuestionObject, setCompanyQuestionObject] = useState(
    props.companyFormationQuestionArray.find(
      (x) => x.idCompanyFormationQuestion === 6
    )
  );
  const [companySampleProtocollAnswer, setCompanySampleProtocollAnswer] =
    useState(
      getCompanyFormationAnswer(6, props.companyFormationAnswerArray, 18)
    );

  useEffect(() => {
    const foundObject = props.companyFormationAnswerArray.find(
      (x) => x.idCompanyFormationQuestion === 6
    );

    if (foundObject === undefined) {
      props.setCompanyFormationAnswerArray([
        ...props.companyFormationAnswerArray,
        companySampleProtocollAnswer,
      ]);
    } else {
      props.setCompanyFormationAnswerArray([
        ...props.companyFormationAnswerArray.map((x) =>
          x.idCompanyFormationQuestion === 6 ? companySampleProtocollAnswer : x
        ),
      ]);
    }
  }, [companySampleProtocollAnswer]);

  return (
    <>
      <DialogResponsive
        open={idShareHolder !== null}
        onClose={() => setIdShareHolder(null)}
        maxWidth="md"
        title={
          idShareHolder === null || idShareHolder === 0
            ? checkTranslation(t, "new_person", "Neue Person")
            : checkTranslation(t, "new_person", "Person bearbeiten")
        }
      >
        <Box sx={{ mt: 2 }}>
          {idShareHolder !== null && (
            <ShareHolderEdit
              idShareHolder={idShareHolder}
              handleClose={() => setIdShareHolder(null)}
              shareHolderArray={props.shareHolderArray}
              setShareHolderArray={props.setShareHolderArray}
              disableSelectCompanyType
            />
          )}
        </Box>
      </DialogResponsive>

      <Box sx={{ mt: 5 }} />
      <Typography variant="h4" sx={{ mb: 3 }}>
        Vertretung
      </Typography>

      <Typography>
        {props.isCompanyNew ? (
          <>
            Eine Gesellschaft mit beschränkter Haftung ist eine juristische
            Person. Sie muss bei allen Rechtsgeschäften vertreten werden. Diese
            Vertretung erfolgt durch die Geschäftsführer und Prokuristen der
            GmbH.{" "}
          </>
        ) : (
          <>
            Wenn Sie Änderungen bzgl. der Vertretung der Gesellschaft vornehmen
            wollen, können Sie uns diese hiermit mitteilen.
          </>
        )}

        {/*
                Geschäftsführer sind die obersten Vertreter der GmbH. Sie sind für die Leitung der Gesellschaft verantwortlich und treffen Entscheidungen in allen wichtigen Fragen. Geschäftsführer können nur durch Beschluss der Gesellschafterversammlung bestellt werden und müssen im Handelsregister eingetragen sein.
                <p />
                Prokuristen hingegen haben im Vergleich zu Geschäftsführern eine eingeschränkte Vertretungsmacht. Sie dürfen nur die Geschäfte führen, die ihnen ausdrücklich vom Geschäftsführer übertragen wurden. Prokuristen können von Geschäftsführern bestellt werden, aber auch von anderen Prokuristen oder durch Beschluss der Gesellschafterversammlung.
                <p />
                Eine wichtige Unterscheidung zwischen Geschäftsführern und Prokuristen ist auch ihre Haftung. Geschäftsführer haften für alle Verbindlichkeiten der GmbH, während Prokuristen nur für solche Verbindlichkeiten haften, die sie selbst verursacht haben oder für die sie ausdrücklich haften. Zudem können Prokuristen nicht ohne Zustimmung der Gesellschafterversammlung für die GmbH im Außenverhältnis haften.
                <p />
                In der Praxis arbeiten Geschäftsführer und Prokuristen oft eng zusammen, um die Geschäfte der GmbH zu führen und zu leiten. Sie haben dabei unterschiedliche Aufgaben und Verantwortlichkeiten, aber beide sind wichtige Bestandteile der Vertretung einer GmbH.
                 */}
        <p />
      </Typography>

      {props.isCompanyNew && (
        <>
          <Box sx={{ mt: 10 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Musterprotokoll
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                {companyQuestionObject?.Description}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                type="number"
                label="Musterprotokoll"
                fullWidth
                value={companySampleProtocollAnswer.idCompanyFormationOption}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanySampleProtocollAnswer({
                    ...companySampleProtocollAnswer,
                    idCompanyFormationOption: Number(event.target.value),
                  })
                }
                select
              >
                {props.companyFormationOptionArray
                  .filter((x) => x.idCompanyFormationQuestion === 6)
                  .map((x) => (
                    <MenuItem
                      key={`idCompanyFormationQuestion-${x.idCompanyFormationOption}`}
                      value={x.idCompanyFormationOption}
                    >
                      {x.CompanyFormationOption}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </>
      )}

      <Box sx={{ mt: 5 }} />
      <Button
        variant="contained"
        sx={{ float: "right", mt: 3 }}
        onClick={() => {
          setIdShareHolder(0);
        }}
      >
        Person hinzufügen
      </Button>

      <Box sx={{ mt: 10 }} />
      <Typography variant="h6" sx={{ mb: 1 }}>
        Geschäftsführer
      </Typography>
      <Typography variant="caption" sx={{ mb: 1 }}>
        Nur natürliche Personen können Geschäftsführer sein!
      </Typography>
      <SelectShareHolderEdit
        key="ceo"
        idShareHolderType={2}
        shareHolderArray={props.shareHolderArray}
        setShareHolderArray={props.setShareHolderArray}
        shareHolderRelation={props.shareHolderRelation}
        setShareHolderRelation={props.setShareHolderRelation}
        disableAddNewButton
        disableSelectCompanyType
        enableOneRole
      />

      <Box sx={{ mt: 10 }} />
      <Typography variant="h6">Prokuristen</Typography>
      <Typography variant="caption" sx={{ mb: 1 }}>
        Nur natürliche Personen können Prokurist sein!
      </Typography>
      <SelectShareHolderEdit
        key="procura"
        idShareHolderType={3}
        shareHolderArray={props.shareHolderArray}
        setShareHolderArray={props.setShareHolderArray}
        shareHolderRelation={props.shareHolderRelation}
        setShareHolderRelation={props.setShareHolderRelation}
        disableAddNewButton
        disableSelectCompanyType
        enableOneRole
      />

      <Box sx={{ mt: 5, display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => props.handleBack()}
          sx={{ mr: 1 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={() => props.handleNext()}
          type="submit"
          variant="contained"
        >
          Weiter
        </Button>
      </Box>
    </>
  );
};
