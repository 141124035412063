import React from "react";
import { IPurchaseContractHasPerson } from "../../../../../../Interfaces/IPerson";
import { GridBaseData } from "../../../../../Person/GridBaseData";

interface IProps {
  currentPerson: IPurchaseContractHasPerson;
  setCurrentPerson: Function;
  personArray: IPurchaseContractHasPerson[];
  showTaxInformation: boolean;
  showBankInformation: boolean;
}


export const PurchaseContractPersonEdit:React.FC<IProps> = (props) => {

    return(
        <>
            <GridBaseData
                personObject={props.currentPerson}
                personArray={props.personArray}
                setPersonObject={props.setCurrentPerson}
                showDeathSettings={false}
                forceDeathSettings={false}
                minimalRequiremend={true}
                showTaxNumber={props.showTaxInformation}
                showBankInformations={props.showBankInformation}
            />

        </>
    )
}
