import { ICountry } from "../Interfaces/ICountry";

export const staticCountryArray: ICountry[] = [
  { idCountry: 2, Country: "Afghanistan", Nationality: "afghanisch" },
  { idCountry: 3, Country: "Ägypten", Nationality: "ägyptisch" },
  { idCountry: 4, Country: "Albanien", Nationality: "albanisch" },
  { idCountry: 5, Country: "Algerien", Nationality: "algerisch" },
  { idCountry: 6, Country: "Andorra", Nationality: "andorranisch" },
  { idCountry: 7, Country: "Angola", Nationality: "angolanisch" },
  { idCountry: 8, Country: "Antigua und Barbuda", Nationality: "antiguanisch" },
  {
    idCountry: 9,
    Country: "Äquatorialguinea",
    Nationality: "äquatorial­guineisch",
  },
  { idCountry: 10, Country: "Argentinien", Nationality: "argentinisch" },
  { idCountry: 11, Country: "Armenien", Nationality: "armenisch" },
  { idCountry: 12, Country: "Aserbaidschan", Nationality: "aserbaidschanisch" },
  { idCountry: 13, Country: "Äthiopien", Nationality: "äthiopisch" },
  { idCountry: 14, Country: "Australien", Nationality: "australisch" },
  { idCountry: 15, Country: "Bahamas", Nationality: "bahamaisch" },
  { idCountry: 16, Country: "Bahrain", Nationality: "bahrainisch" },
  { idCountry: 17, Country: "Bangladesch", Nationality: "bangladeschisch" },
  { idCountry: 18, Country: "Barbados", Nationality: "barbadisch" },
  { idCountry: 19, Country: "Belarus", Nationality: "belarussisch" },
  { idCountry: 20, Country: "Belgien", Nationality: "belgisch" },
  { idCountry: 21, Country: "Belize", Nationality: "belizisch" },
  { idCountry: 22, Country: "Benin", Nationality: "beninisch" },
  { idCountry: 23, Country: "Bhutan", Nationality: "bhutanisch" },
  {
    idCountry: 24,
    Country: "Bolivien, Plurinationaler Staat",
    Nationality: "bolivianisch",
  },
  {
    idCountry: 25,
    Country: "Bosnien und Herzegowina",
    Nationality: "bosnisch­herzegowinisch",
  },
  { idCountry: 26, Country: "Botsuana", Nationality: "botsuanisch" },
  { idCountry: 27, Country: "Brasilien", Nationality: "brasilianisch" },
  { idCountry: 28, Country: "Brunei Darussalam", Nationality: "bruneiisch" },
  { idCountry: 29, Country: "Bulgarien", Nationality: "bulgarisch" },
  { idCountry: 30, Country: "Burkina Faso", Nationality: "burkinisch" },
  { idCountry: 31, Country: "Burundi", Nationality: "burundisch" },
  { idCountry: 32, Country: "Cabo Verde", Nationality: "Cabo Verde" },
  { idCountry: 33, Country: "Chile", Nationality: "chilenisch" },
  { idCountry: 34, Country: "China", Nationality: "chinesisch" },
  { idCountry: 35, Country: "Cookinseln", Nationality: "Cookinseln" },
  { idCountry: 36, Country: "Costa Rica", Nationality: "costa­ricanisch" },
  { idCountry: 37, Country: "Côte d'Ivoire", Nationality: "ivorisch" },
  { idCountry: 38, Country: "Dänemark", Nationality: "dänisch" },
  { idCountry: 1, Country: "Deutschland", Nationality: "deutsch" },
  { idCountry: 39, Country: "Dominica", Nationality: "dominicanisch" },
  {
    idCountry: 40,
    Country: "Dominikanische Republik",
    Nationality: "dominikanisch",
  },
  { idCountry: 41, Country: "Dschibuti", Nationality: "dschibutisch" },
  { idCountry: 42, Country: "Ecuador", Nationality: "ecuadorianisch" },
  { idCountry: 43, Country: "El Salvador", Nationality: "salvadorianisch" },
  { idCountry: 44, Country: "Eritrea", Nationality: "eritreisch" },
  { idCountry: 45, Country: "Estland", Nationality: "estnisch" },
  { idCountry: 46, Country: "Eswatini", Nationality: "Eswatini" },
  { idCountry: 47, Country: "Fidschi", Nationality: "fidschianisch" },
  { idCountry: 48, Country: "Finnland", Nationality: "finnisch" },
  { idCountry: 49, Country: "Frankreich", Nationality: "französisch" },
  { idCountry: 50, Country: "Gabun", Nationality: "gabunisch" },
  { idCountry: 51, Country: "Gambia", Nationality: "gambisch" },
  { idCountry: 52, Country: "Georgien", Nationality: "georgisch" },
  { idCountry: 53, Country: "Ghana", Nationality: "ghanaisch" },
  { idCountry: 54, Country: "Grenada", Nationality: "grenadisch" },
  { idCountry: 55, Country: "Griechenland", Nationality: "griechisch" },
  { idCountry: 56, Country: "Guatemala", Nationality: "guatemaltekisch" },
  { idCountry: 57, Country: "Guinea", Nationality: "guineisch" },
  { idCountry: 58, Country: "Guinea-Bissau", Nationality: "guinea­bissauisch" },
  { idCountry: 59, Country: "Guyana", Nationality: "guyanisch" },
  { idCountry: 60, Country: "Haiti", Nationality: "haitianisch" },
  { idCountry: 61, Country: "Heiliger Stuhl", Nationality: "Heiliger Stuhl" },
  { idCountry: 62, Country: "Honduras", Nationality: "honduranisch" },
  { idCountry: 63, Country: "Indien", Nationality: "indisch" },
  { idCountry: 64, Country: "Indonesien", Nationality: "indonesisch" },
  { idCountry: 65, Country: "Irak", Nationality: "irakisch" },
  {
    idCountry: 66,
    Country: "Iran, Islamische Republik",
    Nationality: "iranisch",
  },
  { idCountry: 67, Country: "Irland", Nationality: "irisch" },
  { idCountry: 68, Country: "Island", Nationality: "isländisch" },
  { idCountry: 69, Country: "Israel", Nationality: "israelisch" },
  { idCountry: 70, Country: "Italien", Nationality: "italienisch" },
  { idCountry: 71, Country: "Jamaika", Nationality: "jamaikanisch" },
  { idCountry: 72, Country: "Japan", Nationality: "japanisch" },
  { idCountry: 73, Country: "Jemen", Nationality: "jemenitisch" },
  { idCountry: 74, Country: "Jordanien", Nationality: "jordanisch" },
  { idCountry: 75, Country: "Kambodscha", Nationality: "kambodschanisch" },
  { idCountry: 76, Country: "Kamerun", Nationality: "kamerunisch" },
  { idCountry: 77, Country: "Kanada", Nationality: "kanadisch" },
  { idCountry: 78, Country: "Kasachstan", Nationality: "kasachisch" },
  { idCountry: 79, Country: "Katar", Nationality: "katarisch" },
  { idCountry: 80, Country: "Kenia", Nationality: "kenianisch" },
  { idCountry: 81, Country: "Kirgisistan", Nationality: "kirgisisch" },
  { idCountry: 82, Country: "Kiribati", Nationality: "kiribatisch" },
  { idCountry: 83, Country: "Kolumbien", Nationality: "kolumbianisch" },
  { idCountry: 84, Country: "Komoren", Nationality: "komorisch" },
  { idCountry: 85, Country: "Kongo", Nationality: "kongolesisch" },
  {
    idCountry: 86,
    Country: "Kongo, Demokratische Republik",
    Nationality: "kongolesisch",
  },
  {
    idCountry: 87,
    Country: "Korea, Demokratische Volksrepublik",
    Nationality: "koreanisch",
  },
  { idCountry: 88, Country: "Korea, Republik", Nationality: "koreanisch" },
  { idCountry: 89, Country: "Kosovo", Nationality: "kosovarisch" },
  { idCountry: 90, Country: "Kroatien", Nationality: "kroatisch" },
  { idCountry: 91, Country: "Kuba", Nationality: "kubanisch" },
  { idCountry: 92, Country: "Kuwait", Nationality: "kuwaitisch" },
  {
    idCountry: 93,
    Country: "Laos, Demokratische Volksrepublik ",
    Nationality: "laotisch",
  },
  { idCountry: 94, Country: "Lesotho", Nationality: "lesothisch" },
  { idCountry: 95, Country: "Lettland", Nationality: "lettisch" },
  { idCountry: 96, Country: "Libanon", Nationality: "libanesisch" },
  { idCountry: 97, Country: "Liberia", Nationality: "liberianisch" },
  { idCountry: 98, Country: "Libyen", Nationality: "libysch" },
  { idCountry: 99, Country: "Liechtenstein", Nationality: "liechtensteinisch" },
  { idCountry: 100, Country: "Litauen", Nationality: "litauisch" },
  { idCountry: 101, Country: "Luxemburg", Nationality: "luxemburgisch" },
  { idCountry: 102, Country: "Madagaskar", Nationality: "madagassisch" },
  { idCountry: 103, Country: "Malawi", Nationality: "malawisch" },
  { idCountry: 104, Country: "Malaysia", Nationality: "malaysisch" },
  { idCountry: 105, Country: "Malediven", Nationality: "maledivisch" },
  { idCountry: 106, Country: "Mali", Nationality: "malisch" },
  { idCountry: 107, Country: "Malta", Nationality: "maltesisch" },
  { idCountry: 108, Country: "Marokko", Nationality: "marokkanisch" },
  { idCountry: 109, Country: "Marshallinseln", Nationality: "marshallisch" },
  { idCountry: 110, Country: "Mauretanien", Nationality: "mauretanisch" },
  { idCountry: 111, Country: "Mauritius", Nationality: "mauritisch" },
  { idCountry: 112, Country: "Mexiko", Nationality: "mexikanisch" },
  {
    idCountry: 113,
    Country: "Mikronesien, Föderierte Staaten von",
    Nationality: "mikronesisch",
  },
  { idCountry: 114, Country: "Moldau, Republik", Nationality: "moldauisch" },
  { idCountry: 115, Country: "Monaco", Nationality: "monegassisch" },
  { idCountry: 116, Country: "Mongolei", Nationality: "mongolisch" },
  { idCountry: 117, Country: "Montenegro", Nationality: "montenegrinisch" },
  { idCountry: 118, Country: "Mosambik", Nationality: "mosambikanisch" },
  { idCountry: 119, Country: "Myanmar", Nationality: "myanmarisch" },
  { idCountry: 120, Country: "Namibia", Nationality: "namibisch" },
  { idCountry: 121, Country: "Nauru", Nationality: "nauruisch" },
  { idCountry: 122, Country: "Nepal", Nationality: "nepalesisch" },
  { idCountry: 123, Country: "Neuseeland", Nationality: "neuseeländisch" },
  { idCountry: 124, Country: "Nicaragua", Nationality: "nicaraguanisch" },
  {
    idCountry: 125,
    Country: "Niederlande, Königreich der",
    Nationality: "niederländisch",
  },
  { idCountry: 126, Country: "Niger", Nationality: "nigrisch" },
  { idCountry: 127, Country: "Nigeria", Nationality: "nigerianisch" },
  { idCountry: 128, Country: "Niue", Nationality: "niueanisch" },
  { idCountry: 129, Country: "Nordmazedonien", Nationality: "Nordmazedonien" },
  { idCountry: 130, Country: "Norwegen", Nationality: "norwegisch" },
  { idCountry: 131, Country: "Oman", Nationality: "omanisch" },
  { idCountry: 132, Country: "Österreich", Nationality: "österreichisch" },
  { idCountry: 133, Country: "Pakistan", Nationality: "pakistanisch" },
  { idCountry: 134, Country: "Palau", Nationality: "palauisch" },
  { idCountry: 135, Country: "Panama", Nationality: "panamaisch" },
  {
    idCountry: 136,
    Country: "Papua-Neuguinea",
    Nationality: "papua-neuguineisch",
  },
  { idCountry: 137, Country: "Paraguay", Nationality: "paraguayisch" },
  { idCountry: 138, Country: "Peru", Nationality: "peruanisch" },
  { idCountry: 139, Country: "Philippinen", Nationality: "philippinisch" },
  { idCountry: 140, Country: "Polen", Nationality: "polnisch" },
  { idCountry: 141, Country: "Portugal", Nationality: "portugiesisch" },
  { idCountry: 142, Country: "Ruanda", Nationality: "ruandisch" },
  { idCountry: 143, Country: "Rumänien", Nationality: "rumänisch" },
  { idCountry: 144, Country: "Russische Föderation", Nationality: "russisch" },
  { idCountry: 145, Country: "Salomonen", Nationality: "salomonisch" },
  { idCountry: 146, Country: "Sambia", Nationality: "sambisch" },
  { idCountry: 147, Country: "Samoa", Nationality: "samoanisch" },
  { idCountry: 148, Country: "San Marino", Nationality: "san­marinesisch" },
  {
    idCountry: 149,
    Country: "São Tomé und Príncipe",
    Nationality: "são­toméisch",
  },
  { idCountry: 150, Country: "Saudi-Arabien", Nationality: "saudi­arabisch" },
  { idCountry: 151, Country: "Schweden", Nationality: "schwedisch" },
  { idCountry: 152, Country: "Schweiz", Nationality: "schweizerisch" },
  { idCountry: 153, Country: "Senegal", Nationality: "senegalesisch" },
  { idCountry: 154, Country: "Serbien ", Nationality: "serbisch" },
  { idCountry: 155, Country: "Seychellen", Nationality: "seychellisch" },
  { idCountry: 156, Country: "Sierra Leone", Nationality: "sierra­leonisch" },
  { idCountry: 157, Country: "Simbabwe", Nationality: "simbabwisch" },
  { idCountry: 158, Country: "Singapur", Nationality: "singapurisch" },
  { idCountry: 159, Country: "Slowakei", Nationality: "slowakisch" },
  { idCountry: 160, Country: "Slowenien", Nationality: "slowenisch" },
  { idCountry: 161, Country: "Somalia", Nationality: "somalisch" },
  { idCountry: 162, Country: "Spanien", Nationality: "spanisch" },
  { idCountry: 163, Country: "Sri Lanka", Nationality: "sri­lankisch" },
  {
    idCountry: 164,
    Country: "St. Kitts und Nevis",
    Nationality: "St. Kitts und Nevis",
  },
  { idCountry: 165, Country: "St. Lucia", Nationality: "lucianisch" },
  {
    idCountry: 166,
    Country: "St. Vincent und die Grenadinen",
    Nationality: "vincentisch",
  },
  { idCountry: 167, Country: "Südafrika", Nationality: "südafrikanisch" },
  { idCountry: 168, Country: "Sudan", Nationality: "sudanesisch" },
  { idCountry: 169, Country: "Südsudan", Nationality: "südsudanesisch" },
  { idCountry: 170, Country: "Suriname", Nationality: "surinamisch" },
  {
    idCountry: 171,
    Country: "Syrien, Arabische Republik",
    Nationality: "syrisch",
  },
  { idCountry: 172, Country: "Tadschikistan", Nationality: "tadschikisch" },
  {
    idCountry: 173,
    Country: "Tansania, Vereinigte Republik",
    Nationality: "tansanisch",
  },
  { idCountry: 174, Country: "Thailand", Nationality: "thailändisch" },
  { idCountry: 175, Country: "Timor-Leste", Nationality: "Timor-Leste" },
  { idCountry: 176, Country: "Togo", Nationality: "togoisch" },
  { idCountry: 177, Country: "Tonga", Nationality: "tongaisch" },
  {
    idCountry: 178,
    Country: "Trinidad und Tobago",
    Nationality: "Trinidad und Tobago",
  },
  { idCountry: 179, Country: "Tschad", Nationality: "tschadisch" },
  { idCountry: 180, Country: "Tschechien", Nationality: "tschechisch" },
  { idCountry: 181, Country: "Tunesien", Nationality: "tunesisch" },
  { idCountry: 182, Country: "Türkei", Nationality: "türkisch" },
  { idCountry: 183, Country: "Turkmenistan", Nationality: "turkmenisch" },
  { idCountry: 184, Country: "Tuvalu", Nationality: "tuvaluisch" },
  { idCountry: 185, Country: "Uganda", Nationality: "ugandisch" },
  { idCountry: 186, Country: "Ukraine", Nationality: "ukrainisch" },
  { idCountry: 187, Country: "Ungarn", Nationality: "ungarisch" },
  { idCountry: 188, Country: "Uruguay", Nationality: "uruguayisch" },
  { idCountry: 189, Country: "Usbekistan", Nationality: "usbekisch" },
  { idCountry: 190, Country: "Vanuatu", Nationality: "vanuatuisch" },
  { idCountry: 191, Country: "Vatikanstadt", Nationality: "vatikanisch" },
  {
    idCountry: 192,
    Country: "Venezuela, Bolivarische Republik",
    Nationality: "venezolanisch",
  },
  {
    idCountry: 193,
    Country: "Vereinigte Arabische Emirate",
    Nationality: "Vereinigte Arabische Emirate",
  },
  {
    idCountry: 194,
    Country: "Vereinigte Staaten",
    Nationality: "der Vereinigten Staaten (von Amerika) /amerikanisch",
  },
  {
    idCountry: 195,
    Country: "Vereinigtes Königreich",
    Nationality: "britisch",
  },
  { idCountry: 196, Country: "Vietnam", Nationality: "vietnamesisch" },
  {
    idCountry: 197,
    Country: "Zentralafrikanische Republik",
    Nationality: "zentralafrikanisch",
  },
  { idCountry: 198, Country: "Zypern", Nationality: "zyprisch" },
];
