import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  ThemeProvider,
  Typography,
  type Theme,
} from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FoundingDialog } from './components/public/Dialog/Founding/FoundingDialog';
import { InviteDialogAddLegalTransaction } from './components/public/Invite/InviteDialogAddLegalTransaction';
import { InviteDialogAddContact } from './components/public/Invite/InviteDialogAddContact';
import { InheritDialogNew } from './components/public/Dialog/InheritNew/InheritDialogNew';
import { ResetPasswort } from './components/public/ResetPasswort/ResetPasswort';
import { DialogSelecter } from './components/core/DialogSelecter';
import { PurchaseContractDialog } from './components/public/Dialog/PurchaseContract/PurchaseContractDialog';
import { CompanyChange } from './components/public/Dialog/CompanyChange/CompanyChange';
import { FoundRegisteredAssociationDialog } from './components/public/Dialog/FoundRegisteredAssociation/FoundRegisteredAssociationDialog';

import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { CopyrightLextorByte } from './CopyrightLextorByte';
import { RAChange } from './components/public/Dialog/ChangeRegisteredAssociation/RAChange';
import { MortgageDialog } from './components/public/Dialog/Mortgage/MortgageDialogNew';
import { CustomCircularProgress } from './components/generic/CustomCircularProgress';
import { IConfig } from './Interfaces/IConfig';
import { useFetch } from './hooks/useFetch';

function App() {
  const [config, setConfig, wasSuccessfullyConfig] = useFetch<
    IConfig | undefined
  >('/config');
  const [isWarningTesting, setIsWarningTesting] = useState(false);
  const [isWarningTestingConfirmed, setIsWarningTestingConfirmed] =
    useState(false);
  const [theme, setTheme] = useState<Theme | undefined>(undefined);

  useEffect(() => {
    if (!config) return;

    setTheme(
      createTheme({
        palette: {
          primary: {
            main: config.ci_color_primary,
          },
          secondary: {
            main: config.ci_color_secondary,
          },
          text: {
            primary: config.ci_color_text,
          },
        },
      })
    );

    const link = document.createElement('link');

    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = `data:image/x-icon;base64,${config.favicon}`;

    const oldLink = document.getElementById('dynamic-favicon');
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
    document.title = `Dialoge - ${config.company_name}`;

    const confirm_testing = localStorage.getItem('confirm_testing');
    if (config.is_testing && confirm_testing !== 'true') {
      setIsWarningTesting(true);
    }
  }, [config]);

  i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'de',
      lng: 'de',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: '/locales/{{lng}}/translation.json',
      },
    });

  if (!wasSuccessfullyConfig) {
    return (
      <Alert severity='warning'>
        Unsere Dialoge sind derzeitig nicht verfübar.
        <br />
        Bitte versuchen Sie es später erneut!
      </Alert>
    );
  } else if (!config || !theme) {
    return <CustomCircularProgress />;
  } else {
    return (
      <Suspense fallback='loading'>
        <ThemeProvider theme={theme}>
          <Dialog open={isWarningTesting}>
            <DialogContent>
              <Alert severity='warning' icon={false}>
                <Typography component='span'>
                  <b>Demo- und Testseite</b>
                  <br />
                  Bitte geben Sie keine echten Daten ein, da die Sicherheit der
                  Daten nicht garantiert werden kann.
                  <br />
                  <br />
                </Typography>
                <FormControlLabel
                  label={
                    <Typography component='span'>
                      <b>
                        Ich habe zur Kenntniss genommen, dass es sich um eine
                        Demo- und Testseite handelt.
                      </b>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={isWarningTestingConfirmed}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setIsWarningTestingConfirmed(event.target.checked)
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                      required
                    />
                  }
                />
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!isWarningTestingConfirmed}
                variant='contained'
                onClick={() => {
                  localStorage.setItem('confirm_testing', 'true');
                  window.location.reload();
                }}
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog>
          <Box component='main' sx={{ flexGrow: 1 }}>
            <Router>
              <Routes>
                <Route
                  path='/'
                  element={<DialogSelecter configObject={config} />}
                />

                <Route
                  path='/founding'
                  element={<FoundingDialog configObject={config} />}
                />
                <Route
                  path='/inherit'
                  element={<InheritDialogNew configObject={config} />}
                />

                <Route
                  path='/mortgage'
                  element={<MortgageDialog configObject={config} />}
                />

                <Route
                  path='/companychange'
                  element={<CompanyChange configObject={config} />}
                />

                <Route
                  path='/registeredassociation/founding'
                  element={
                    <FoundRegisteredAssociationDialog configObject={config} />
                  }
                />
                <Route
                  path='/registeredassociation/change'
                  element={<RAChange configObject={config} />}
                />

                <Route
                  path='/purchase'
                  element={<PurchaseContractDialog configObject={config} />}
                />

                <Route
                  path='/invite/legaltransaction/:token'
                  element={
                    <InviteDialogAddLegalTransaction configObject={config} />
                  }
                />
                <Route
                  path='/invite/contact/:token'
                  element={<InviteDialogAddContact configObject={config} />}
                />

                <Route
                  path='/resetpassword/:idContact/:token'
                  element={<ResetPasswort configObject={config} />}
                />
              </Routes>
            </Router>
          </Box>
          <CopyrightLextorByte configObject={config} />
        </ThemeProvider>
      </Suspense>
    );
  }
}

export default App;
