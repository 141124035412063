import React, { useState } from "react";
import { IPurchaseContractHasPerson } from "../../../../../../Interfaces/IPerson";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PurchaseContractPersonEdit } from "./PurchaseContractPersonEdit";
import { PurchaseContractPersonRow } from "./PurchaseContractPersonRow";
import { useTranslation } from "react-i18next";
import { checkTranslation } from "../../../../../core/standardFunctions";
import { DialogResponsive } from "../../../../../core/DialogResponsive";

interface IProps {
  handleBack: Function;
  handleNext: Function;
  idPruchaseContractRelationType: number;
  title: string;
  text: string;
  personArray: IPurchaseContractHasPerson[];
  setPersonArray: Function;
}

export const getPurchaseContractPerson = (
  idPerson: number,
  idPurchaseContractRelationType: number,
  personArray: IPurchaseContractHasPerson[],
  isNew: boolean = false
) => {
  const testObject = personArray.find((x) => x.idPerson === idPerson);
  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...personArray.map((x) => x.idPerson)) - 1;
    if (minId >= 0) {
      minId = -1;
    }

    return {
      idPerson: minId,
      Birthday: "",
      Birthplace: "",
      DateOfDeath: null,
      FirstName: "",
      idAcademicTitle: 1,
      idCountry: 1,
      idGender: -1,
      idInherit: -1,
      idPostcode: null,
      LastName: "",
      PlaceOfDeath: null,
      Street: "",
      Relation_idPerson: null,
      StreetAditional: "",
      StreetNr: "",
      Surname: null,
      idBusinessForm: 1,
      idPurchaseContractRelationType: idPurchaseContractRelationType,

      Email: null,
      PhoneNumber: null,

      TaxNumber: null,
      IBAN: null,
      BIC: null,
      Bank: null,
    } as IPurchaseContractHasPerson;
  }
};

export const PurchaseContractPersonOverview: React.FC<IProps> = (props) => {
  const [newPerson, setNewPerson] = useState(
    getPurchaseContractPerson(
      -1,
      props.idPruchaseContractRelationType,
      props.personArray,
      true
    )
  );
  const [isOpenNew, setIsOpenNew] = useState(false);
  const { t } = useTranslation();

  const handleOpenNew = () => {
    setNewPerson(
      getPurchaseContractPerson(
        -1,
        props.idPruchaseContractRelationType,
        props.personArray,
        true
      )
    );
    setIsOpenNew(true);
  };

  const handleAdd = () => {
    props.setPersonArray([...props.personArray, newPerson]);
    setIsOpenNew(false);
  };

  return (
    <>
      <DialogResponsive
        open={isOpenNew}
        onClose={() => setIsOpenNew(false)}
        maxWidth="lg"
        title={checkTranslation(t, "add_person", "Person hinzufügen")}
        actions={
          <>
            <Button variant="outlined" onClick={() => setIsOpenNew(false)}>
              {checkTranslation(t, "cancel", "Abbruch")}
            </Button>
            <Button
              variant="contained"
              onClick={handleAdd}
              disabled={
                newPerson.FirstName === "" ||
                newPerson.LastName === "" ||
                newPerson.idGender < 0
              }
            >
              {checkTranslation(t, "apply", "Hinzufügen")}
            </Button>
          </>
        }
      >
        <PurchaseContractPersonEdit
          currentPerson={newPerson}
          setCurrentPerson={setNewPerson}
          personArray={props.personArray}
          showBankInformation
          showTaxInformation
        />
      </DialogResponsive>

      <Box sx={{ mt: 5 }} />
      <Typography variant="h4">{props.title}</Typography>
      <Typography sx={{ mb: 3 }}>{props.text}</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nr.</TableCell>
            <TableCell>
              {checkTranslation(t, "first_name", "Vorname")}
            </TableCell>
            <TableCell>
              {checkTranslation(t, "last_name", "Nachname")}
            </TableCell>
            <TableCell>{checkTranslation(t, "actions", "Aktionen")}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.personArray
            .filter(
              (x) =>
                x.idPurchaseContractRelationType ===
                props.idPruchaseContractRelationType
            )
            .map((x, idx) => (
              <PurchaseContractPersonRow
                key={`idPerson-${x.idPerson}`}
                index={idx}
                currentObject={x}
                personArray={props.personArray}
                setPersonArray={props.setPersonArray}
              />
            ))}
        </TableBody>
      </Table>
      <Button sx={{ mt: 5 }} variant="contained" onClick={handleOpenNew}>
        {checkTranslation(t, "add", "Hinzufügen")}
      </Button>

      <Box sx={{ mt: 10 }} />

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => props.handleBack()}
          sx={{ mr: 1 }}
        >
          {checkTranslation(t, "button_back", "Zurück")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          type="submit"
          variant="contained"
          onClick={() => props.handleNext()}
          disabled={
            props.personArray.filter(
              (x) =>
                x.idPurchaseContractRelationType ===
                props.idPruchaseContractRelationType
            ).length === 0
          }
        >
          {checkTranslation(t, "button_next", "Weiter")}
        </Button>
      </Box>
    </>
  );
};
