import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";

interface IProps {
  handleBack: Function;
  handleNext: Function;
  mortgageObject: IMortgage;
  setMortgageObject: Function;
}

export const MortgageGeneral: React.FC<IProps> = (props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [beneficiary, setBeneficiary] = useState<boolean | null>(
    props.mortgageObject.BeneficiaryType
      ? props.mortgageObject.BeneficiaryType === "private"
      : null
  );
  const [propertyBuy, setPropertyBuy] = useState<boolean | null>(
    props.mortgageObject.PropertyForBuying === false ||
      props.mortgageObject.PropertyForBuying === true
      ? props.mortgageObject.PropertyForBuying
      : null
  );
  const [propertyOwned, setPropertyOwned] = useState<boolean | null>(
    props.mortgageObject.PropertyOwned === true ||
      props.mortgageObject.PropertyOwned === false
      ? props.mortgageObject.PropertyOwned
      : null
  );

  useEffect(() => {
    props.setMortgageObject({
      ...props.mortgageObject,
      Beneficiary: beneficiary ? "private" : "institution",
      PropertyForBuying: propertyBuy,
      PropertyOwned: propertyOwned,
    });
  }, [beneficiary, propertyBuy, propertyOwned]);

  const handleBeneficiaryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBeneficiary(event.target.value === "private");
  };

  const handlePropertyBuyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value === "yes"
      ? setPropertyBuy(true)
      : (() => {
          setPropertyBuy(false);
          setPropertyOwned(null);
          props.setMortgageObject({
            ...props.mortgageObject,
            DocumentIndexNumber: "",
            OperationNumber: "",
            PropertyOwned: null,
          });
        })();
  };
  

  const handlePropertyOwnedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "no") {
      props.setMortgageObject({
        ...props.mortgageObject,
        DocumentIndexNumber: "",
        OperationNumber: "",
        PropertyOwned: false
      });
      setPropertyOwned(false);
    } else if (event.target.value === "yes") {
      setPropertyOwned(true);
    }
  };

  const handleSubmit = () => {
    props.handleNext();
  };

  const isFormValid = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSubmit();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <form ref={formRef}>
        <Box sx={{ mt: 6 }} />
        <Typography mt={6} variant="h4">
          Grundpfand
        </Typography>
        <Box display="flex" flexDirection="column">
          <Typography sx={{ mb: 2, mt: 6 }}>
            Für wen soll die Grundschuld bestellt werden?
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="for-who"
              name="who"
              value={
                beneficiary === null
                  ? ""
                  : beneficiary
                  ? "private"
                  : "institution"
              }
              onChange={handleBeneficiaryChange}
            >
              <FormControlLabel
                value="institution"
                control={<Radio required />}
                label="Eine Bank (auch [Bau-] Sparkasse) oder eine Versicherung"
              />
              <FormControlLabel
                value="private"
                control={<Radio required />}
                label="Eine Privatperson oder ein sonstiges Unternehmen"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>
          Wird die Grundschuld zur Finanzierung eines Immobilienkaufs benötigt?
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="property"
            name="property-buy"
            value={propertyBuy === null ? "" : propertyBuy ? "yes" : "no"}
            onChange={handlePropertyBuyChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio required />}
              label="Ja"
            />
            <FormControlLabel
              value="no"
              control={<Radio required />}
              label="Nein"
            />
          </RadioGroup>
        </FormControl>
        {propertyBuy && (
          <>
            <Box sx={{ mt: 6 }} />
            <Typography sx={{ mb: 2 }}>
              Ist der Immobilienkauf bereits erfolgt?
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="property-owned"
                name="property-owned"
                value={
                  propertyOwned === null ? "" : propertyOwned ? "yes" : "no"
                }
                onChange={handlePropertyOwnedChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio required />}
                  label="Ja"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio required />}
                  label="Nein"
                />
              </RadioGroup>
            </FormControl>
            {propertyOwned && (
              <>
                <Box sx={{ mt: 6 }} />
                <Typography sx={{ mb: 2 }}>
                  Geben Sie bitte die Urkundenverzeichnisnummer ein (sog.
                  UVZ-Nummer).
                </Typography>
                <Grid item xs={6} sm={4}>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      label="Urkundenverzeichnisnummer"
                      size="small"
                      fullWidth
                      value={props.mortgageObject.DocumentIndexNumber}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        props.setMortgageObject({
                          ...props.mortgageObject,
                          DocumentIndexNumber: event.target.value,
                        });
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mt: 6 }} />
                <Typography sx={{ mb: 2 }}>
                  Geben Sie die Vorgangsnummer (sechsstellig, befindet sich
                  ausgegraut auf der ersten Seite des Entwurfs) an.
                </Typography>
                <Grid item xs={6} sm={4}>
                  <TextField
                    label="Vorgangsnummer"
                    size="small"
                    fullWidth
                    value={props.mortgageObject.OperationNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      props.setMortgageObject({
                        ...props.mortgageObject,
                        OperationNumber: event.target.value,
                      });
                    }}
                    required
                  />
                </Grid>
              </>
            )}
          </>
        )}
        <Box sx={{ mt: 6 }} />
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => props.handleBack()}
            sx={{ mr: 1 }}
          >
            Zurück
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={() => {
              handleOnSave();
            }}
            variant={isFormValid() ? "contained" : "outlined"}
          >
            Weiter
          </Button>
        </Box>
      </form>
    </>
  );
};
