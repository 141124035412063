import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { InheritPerson, getInheritPerson } from "../InheritPerson";
import { checkIsDisabled } from "../../../../../../core/getDisabledHelper_Inherit";
import { IRequiredFiles } from "../InheritDocument";
import { IMarriage } from "../../../../../../../Interfaces/IMarriage";
import { IInheritHasPerson } from "../../../../../../../Interfaces/IPerson";
import { IInherit } from "../../../../../../../Interfaces/IInherit";
import { DialogResponsive } from "../../../../../../core/DialogResponsive";

interface IProps {
  index: number;
  setIsOpenNew?: Function;
  idMarriage: number;
  inheritObject: IInherit;
  inherit: IInheritHasPerson;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  setMarriageArray: Function;
}

export const getMarriage = (
  isNew: boolean,
  idMarriage: number,
  marriageArray: IMarriage[],
  idPerson: number
) => {
  const testObject = marriageArray.find((x) => x.idMarriage === idMarriage);

  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...marriageArray.map((x) => x.idMarriage)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idMarriage: minId,
      Person1_idPerson: idPerson,
      Person2_idPerson: -1,
      DateOfMarriage: null,
      Devorce_idDocument: null,
      DateOfDevorce: null,
      Marriage_idDocument: -1,
      isDivorced: false,
    } as IMarriage;
  }
};

export const MarriageRow: React.FC<IProps> = (props) => {
  const [currentMarriage, setCurrentMarriage] = useState(
    getMarriage(
      props.setIsOpenNew !== undefined,
      props.idMarriage,
      props.marriageArray,
      props.inherit.idPerson
    )
  );
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(
      currentMarriage.Person2_idPerson,
      props.personArray,
      props.setIsOpenNew !== undefined
    )
  );
  //
  //
  const [editMaririage, setEditMaririage] = useState(currentMarriage);
  const [editPerson, setEditPerson] = useState(currentPerson);
  //
  //const [isDevored, setIsDevored] = useState((editMaririage.DateOfDevorce !== null && editMaririage.DateOfDevorce !== ""));
  const [isDeath, setIsDeath] = useState(false);
  const [isOpen, setIsOpen] = useState(props.setIsOpenNew !== undefined);

  const handleOpen = () => {
    setEditMaririage(currentMarriage);
    setEditPerson(editPerson);
    setIsOpen(true);
  };

  const handleRemove = () => {
    props.setPersonHasDocument([
      ...props.personHasDocument.filter(
        (x) => x.idPerson !== currentPerson.idPerson
      ),
    ]);
    props.setPersonArray([
      ...props.personArray.filter((x) => x.idPerson !== currentPerson.idPerson),
    ]);
    props.setMarriageArray([
      ...props.marriageArray.filter(
        (x) => x.idMarriage !== currentMarriage.idMarriage
      ),
    ]);
    setIsOpen(false);
  };

  const handleClose = () => {
    if (props.setIsOpenNew !== undefined) {
      handleRemove();
      props.setIsOpenNew(false);
    }
    setIsOpen(false);
  };

  const handleSave = () => {
    if (props.setIsOpenNew !== undefined) {
      const newMarriage = {
        ...editMaririage,
        Person2_idPerson: currentPerson.idPerson,
      } as IMarriage;
      props.setMarriageArray([...props.marriageArray, newMarriage]);
      props.setPersonArray([...props.personArray, editPerson]);
      props.setIsOpenNew(false);
    } else {
      props.setMarriageArray([
        ...props.marriageArray.map((x) =>
          x.idMarriage === editMaririage.idMarriage ? editMaririage : x
        ),
      ]);
      props.setPersonArray([
        ...props.personArray.map((x) =>
          x.idPerson === editPerson.idPerson ? editPerson : x
        ),
      ]);
      setCurrentMarriage(editMaririage);
      setCurrentPerson(editPerson);
    }
    setIsOpen(false);
  };

  const getRequirredArray = () => {
    if (editMaririage.isDivorced) {
      return [
        { Title: "Eheurkunde", isOptional: false, idPersonDocumentType: 2 },
        {
          Title: "Scheidungsurkunde",
          isOptional: false,
          idPersonDocumentType: 3,
        },
      ] as IRequiredFiles[];
    } else {
      return [
        { Title: "Eheurkunde", isOptional: false, idPersonDocumentType: 2 },
      ] as IRequiredFiles[];
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSave();
  };

  return (
    <>
      <DialogResponsive
        open={isOpen}
        onClose={handleClose}
        maxWidth="lg"
        title="Ehe"
        actions={
          <>
            <Button variant="outlined" onClick={handleClose}>
              Abbruch
            </Button>
            <Button
              variant="contained"
              disabled={checkIsDisabled(
                props.inheritObject,
                isDeath,
                editPerson
              )}
              onClick={handleSave}
            >
              Speichern
            </Button>
          </>
        }
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 2 }} />
          <Typography variant="h5">Ehe</Typography>

          <Box sx={{ mt: 3, mb: 10 }}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  label="Datum der Eheschließung"
                  size="small"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={editMaririage.DateOfMarriage}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setEditMaririage({
                      ...editMaririage,
                      DateOfMarriage: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item sm={12}>
                <FormControlLabel
                  sx={{ float: "left" }}
                  control={
                    <Switch
                      checked={editMaririage.isDivorced}
                      onChange={() => {
                        if (editMaririage.isDivorced === true) {
                          setEditMaririage({
                            ...editMaririage,
                            DateOfDevorce: null,
                            isDivorced: !editMaririage.isDivorced,
                          });
                        } else {
                          setEditMaririage({
                            ...editMaririage,
                            isDivorced: !editMaririage.isDivorced,
                          });
                        }
                      }}
                      name="gilad"
                    />
                  }
                  label="Geschieden"
                />
              </Grid>
              {editMaririage.isDivorced && (
                <Grid item sm={12}>
                  <TextField
                    label="Datum der Scheidung"
                    size="small"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={
                      editMaririage.DateOfDevorce === null
                        ? ""
                        : editMaririage.DateOfDevorce
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setEditMaririage({
                        ...editMaririage,
                        DateOfDevorce:
                          event.target.value === "" ? null : event.target.value,
                      })
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <InheritPerson
            currentPerson={editPerson}
            setCurrentPerson={setEditPerson}
            inheritObject={props.inheritObject}
            personArray={props.personArray}
            personHasDocument={props.personHasDocument}
            setPersonHasDocument={props.setPersonHasDocument}
            requiredFiles={getRequirredArray()}
            title="Ehepartner"
            showDeathSettings={true}
            setIsDeath={setIsDeath}
          />
        </form>
      </DialogResponsive>

      <TableRow>
        <TableCell>{props.index + 1}</TableCell>
        <TableCell>
          {currentMarriage.DateOfMarriage !== "" &&
          currentMarriage.DateOfMarriage !== undefined &&
          currentMarriage.DateOfMarriage !== null
            ? new Date(currentMarriage.DateOfMarriage).toLocaleDateString(
                "de-DE",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )
            : "k.A"}
        </TableCell>
        <TableCell>
          {!currentMarriage.isDivorced
            ? "-"
            : currentMarriage.DateOfDevorce !== "" &&
              currentMarriage.DateOfDevorce !== undefined &&
              currentMarriage.DateOfDevorce !== null
            ? new Date(currentMarriage.DateOfDevorce).toLocaleDateString(
                "de-DE",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )
            : "k.A"}
        </TableCell>
        <TableCell>
          {`${currentPerson.FirstName} ${currentPerson.LastName}`}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
