import React, { useEffect, useState } from "react";
import { ICompanyFormation } from "../../../../../../Interfaces/ICompanyFormation";
import {
  IShareHolderFullObject,
} from "../../../../../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../../../../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../../../../../Interfaces/ICompanyFormationHasShareHolder";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { roundFunctionNormal } from "../help_functions";

export interface IProps {
  companyFormationObject: ICompanyFormation;
  shareHolderArray: IShareHolderFullObject[];
  companyFormationShareArray: ICompanyFormationShare[];
  shareHolderRelation: ICompanyFormationHasShareHolder[];
}

export const SharesSummaryTable: React.FC<IProps> = (props) => {
  const [totalValue, setTotalValue] = useState(0);
  const [totalValueShares, setTotalValueShares] = useState(0);

  useEffect(() => {
    const idShareHolderArray = props.shareHolderRelation
      .filter((x) => x.idShareHolderType === 1)
      .map((x) => x.idShareHolder);

    setTotalValue(
      props.shareHolderArray
        .filter((x) => idShareHolderArray.indexOf(x.idShareHolder) > -1)
        .map((x) => x.Shares)
        .reduce((a, b) => a + b, 0) * 100
    );
  }, [props.shareHolderArray, props.shareHolderRelation]);

  useEffect(() => {
    setTotalValueShares(
      props.companyFormationShareArray
        .map((x) => x.AmountOfShares * x.ValuePerShare)
        .reduce((a, b) => a + b, 0)
    );
  }, [props.companyFormationShareArray]);

  return (
    <>
      <Box sx={{ mt: 10 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Gesellschafter
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Gesellschafter</TableCell>
            <TableCell>Gesamteinlage in %</TableCell>
            <TableCell>
              Gesamteinlage in EUR /<br /> Wert pro Anteil
            </TableCell>
            <TableCell>Anzahl Anteile</TableCell>
            <TableCell>Nennbetrag Anteil(e)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.shareHolderArray
            .filter(
              (x) =>
                props.shareHolderRelation
                  .filter((y) => y.idShareHolderType === 1)
                  .map((y) => y.idShareHolder)
                  .indexOf(x.idShareHolder) > -1
            )
            .map((x) => {
              const ownShares = props.companyFormationShareArray.filter(
                (y) => y.idShareHolder === x.idShareHolder
              );

              return (
                <>
                  <TableRow>
                    <TableCell>
                      {x.idBusinessForm === 1
                        ? `${x.FirstName} ${x.LastName}`
                        : x.CompanyObject.Company}
                    </TableCell>

                    <TableCell sx={{ width: 150 }}>
                      {(x.Shares * 100).toLocaleString("de-DE") + " %"}
                    </TableCell>

                    <TableCell sx={{ width: 200 }}>
                      {roundFunctionNormal(
                        x.Shares * props.companyFormationObject.ShareCapital
                      ).toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </TableCell>

                    <TableCell sx={{ width: 100 }}>
                      {ownShares
                        .map((x) => x.AmountOfShares)
                        .reduce((a, b) => a + b, 0)}
                    </TableCell>

                    <TableCell sx={{ width: 200 }}>
                      {ownShares
                        .map((x) => x.AmountOfShares * x.ValuePerShare)
                        .reduce((a, b) => a + b, 0)
                        .toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })}
                    </TableCell>
                  </TableRow>

                  {ownShares.map((y, i) => {
                    return (
                      <>
                        <TableRow>
                          {(i === 0 || true) && (
                            <TableCell colSpan={2}></TableCell>
                          )}
                          <TableCell>
                            {y.ValuePerShare.toLocaleString("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </TableCell>
                          <TableCell>{y.AmountOfShares}</TableCell>
                          <TableCell>
                            {(
                              y.ValuePerShare * y.AmountOfShares
                            ).toLocaleString("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </>
              );
            })}

          <TableRow>
            <TableCell>
              <b>Total</b>
            </TableCell>

            <TableCell>
              <Typography
                textAlign="center"
                color={totalValue === 100 ? "info" : "error"}
              >
                <b>{totalValue.toFixed(2).replace(".", ",") + " %"}</b>
              </Typography>
            </TableCell>

            <TableCell colSpan={2}></TableCell>

            <TableCell>
              <Typography
                textAlign="center"
                color={
                  totalValueShares === props.companyFormationObject.ShareCapital
                    ? "info"
                    : "error"
                }
              >
                <b>
                  {totalValueShares.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </b>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
