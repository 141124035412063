import React, { useState } from "react";
import { IRealEstateHasInventoryOption } from "../../../../../../Interfaces/IRealEstateHasInventoryOption";
import { IInventoryOption } from "../../../../../../Interfaces/IInventoryOption";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { RealEstateInventoryOptionEdit } from "./RealEstateInventoryOptionEdit";

interface IProps {
  currentObject: IRealEstateHasInventoryOption;
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setRealEstateHasInventroyOptionArray: Function;
  inventoryOptionArray: IInventoryOption[];
}

export const RealEstateInventoryOptionRow: React.FC<IProps> = (props) => {
  const [editObject, setEditObject] = useState(props.currentObject);
  //
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const handleRemove = () => {
    props.setRealEstateHasInventroyOptionArray([
      ...props.realEstateHasInventroyOptionArray.filter(
        (x) =>
          x.idRealEstate_has_InventoryOption !==
          props.currentObject.idRealEstate_has_InventoryOption
      ),
    ]);
  };

  const handleOpen = () => {
    setEditObject(props.currentObject);
    setIsOpenEdit(true);
  };

  const handleAdd = () => {
    props.setRealEstateHasInventroyOptionArray([
      ...props.realEstateHasInventroyOptionArray.map((x) =>
        x.idRealEstate_has_InventoryOption ===
        props.currentObject.idRealEstate_has_InventoryOption
          ? editObject
          : x
      ),
    ]);
    setIsOpenEdit(false);
  };

  return (
    <>
      <Dialog
        open={isOpenEdit}
        onClose={() => setIsOpenEdit(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Anlage anpassen</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }} />
          <RealEstateInventoryOptionEdit
            currentObject={editObject}
            setCurrentObject={setEditObject}
            inventoryOptionArray={props.inventoryOptionArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setIsOpenEdit(false)}>
            Abbruch
          </Button>
          <Button
            variant="contained"
            disabled={editObject.idInventoryOption < 0}
            onClick={handleAdd}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell>{props.currentObject.Amount}</TableCell>
        <TableCell>{props.currentObject.Title}</TableCell>
        <TableCell>
          {props.currentObject.Description === null
            ? "-"
            : props.currentObject.Description}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
