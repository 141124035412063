import { Height, Search } from "@mui/icons-material";
import { LatLngTuple, Map } from "leaflet";
import L from "leaflet";

import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { IRealEstate } from "../../../../../../Interfaces/IRealEstate";
import { getFetch, useFetch } from "../../../../../../hooks/useFetch";
import { IPostcode } from "../../../../../../Interfaces/IPostcode";
import { IConfig } from "../../../../../../Interfaces/IConfig";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

interface IPropsDraggaleMarker {
  postition: { lat: number; lng: number };
  setPostition: Function;

  setStreet: Function;
  setStreetNr: Function;
  setPostcode: Function;
  city: string;
  setCity: Function;
  setLat: Function;
  setLong: Function;
  setPostcodeObject: Function;
}

interface IPropsMapSearch {
  configObject?: IConfig;
  realEstate: IRealEstate;
  setRealEstate: Function;
  setPostcodeObject: Function;
}

const DraggableMarker: React.FC<IPropsDraggaleMarker> = (props) => {
  const [draggable, setDraggable] = useState(true);
  const markerRef = useRef<any | null>(null);
  //
  const [postcode, setPostcode] = useState("");

  const map = useMapEvents({
    click: (e) => {
      props.setPostition({ lat: e.latlng.lat, lng: e.latlng.lng });
    },
  });

  useEffect(() => {
    props.setLat(props.postition.lat);
    props.setLong(props.postition.lng);

    fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${props.postition.lat}&lon=${props.postition.lng}&format=json`,
      {
        headers: {
          "User-Agent": "DiNoPublic",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        props.setStreet(res.address.road);
        props.setStreetNr(res.address.house_number);
        props.setPostcode(res.address.postcode);
        setPostcode(res.address.postcode);
        "town" in res.address
          ? props.setCity(res.address.town)
          : props.setCity(res.address.city);
      });
  }, [props.postition]);

  const getHandler = (localPostcodeArray: IPostcode[]) => {
    let testObject = localPostcodeArray.find(
      (x) => x.City.toLocaleLowerCase() === props.city.toLocaleLowerCase()
    );

    if (testObject !== undefined) {
      props.setPostcodeObject(testObject);
    } else if (localPostcodeArray.length > 0) {
      props.setPostcodeObject(localPostcodeArray[0]);
    }
  };

  useEffect(() => {
    if (postcode !== "" && props.city !== "") {
      getFetch(`/postcode/public/`, postcode, getHandler);
    }
  }, [postcode, props.city]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          props.setPostition(marker.getLatLng()!);
        }
      },
    }),
    []
  );
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={props.postition}
      ref={markerRef}
    >
      <Popup minWidth={90}>
        <>Sie können den Markierer auf die gewünschte Fläche bewegen</>
      </Popup>
    </Marker>
  );
};

export const MapSearch: React.FC<IPropsMapSearch> = (props) => {
  /*const [position,setPostition] = useState<{ lat: number; lng: number }>({
    lat: 52.759700,
    lng: 13.253910,
  });*/
  const [position, setPostition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [street, setStreet] = useState("");
  const [streetNr, setStreetNr] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  //
  const [postcodeObject, setPostcodeObject] = useState<IPostcode | null>(null);
  //
  const [searchTerm, setSearchTerm] = useState("");
  const [reload, setReload] = useState(0);

  const handleAdd = () => {
    let updatedObject = {
      ...props.realEstate,
      Street: street,
      StreetAditional: null,
      StreetNr: streetNr,
      Longitude: lat,
      Latitude: long,
      Postcode: postcode,
      City: city,
    } as IRealEstate;

    if (postcodeObject !== null) {
      updatedObject.idPostcode = postcodeObject.idPostcode;
      props.setPostcodeObject(postcodeObject);
    }

    props.setRealEstate(updatedObject);
  };

  const searchEngine = (localSearchTermin: string) => {
    if (localSearchTermin !== "") {
      fetch(
        `https://nominatim.openstreetmap.org/search?q=${localSearchTermin}&format=json`,
        {
          headers: {
            "User-Agent": "DiNoPublic",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          let localObject = res;

          if (Array.isArray(res) && res.length > 0) {
            localObject = res[0];
          }

          if ("lat" in localObject && "lon" in localObject) {
            setPostition({ lat: localObject.lat, lng: localObject.lon });
            setReload(reload + 1);
          }
        });
    }
  };

  const searchByText = (e: React.FormEvent) => {
    e.preventDefault();
    searchEngine(searchTerm);
    setSearchTerm("");
  };

  useEffect(() => {
    // Suchen für den Notar
    searchEngine(
      `${props.configObject?.street} ${props.configObject?.street_nr}, ${props.configObject?.postcode}`
    );
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Button onClick={handleAdd} sx={{ float: "right" }} variant="contained">
          Übernehmen
        </Button>
      </Grid>

      <Grid item sm={12}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Straße:</b>
              </TableCell>
              <TableCell>
                <i>{street}</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Nr:</b>
              </TableCell>
              <TableCell>
                <i>{streetNr}</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Stadt:</b>
              </TableCell>
              <TableCell>
                <i>{postcode}</i>, <i>{city}</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Koordinaten:</b>
              </TableCell>
              <TableCell>
                <i>{lat}</i>, <i>{long}</i>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={12}>
        <form onSubmit={searchByText}>
          <Box sx={{ display: "flex", mt: 5 }}>
            <TextField
              label="Suchen"
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value)
              }
              fullWidth
              size="small"
            />

            <IconButton type="submit">
              <Search />
            </IconButton>
          </Box>
        </form>
      </Grid>

      <Grid item xs={12} sm={8}>
        <Collapse in={position !== null}>
          <MapContainer
            key={`MapContainer-${reload}`}
            style={{
              height: 400,
              width: 800,
              alignContent: "center",
              alignItems: "center",
            }}
            center={position || { lat: 0, lng: 0 }}
            zoom={16}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy;  DiNo von Holland & Kreußlein | Karteninformationen &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker
              postition={position || { lat: 0, lng: 0 }}
              setPostition={setPostition}
              setStreet={setStreet}
              setStreetNr={setStreetNr}
              setPostcode={setPostcode}
              setCity={setCity}
              city={city}
              setLat={setLat}
              setLong={setLong}
              setPostcodeObject={setPostcodeObject}
            />
          </MapContainer>
        </Collapse>
      </Grid>
    </Grid>
  );
};
