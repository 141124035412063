import React, { useState } from "react";
import { Box, Button, Fade, Typography } from "@mui/material";
import { IInheritHasPerson } from "../../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../../Interfaces/IPersonHasDocument";
import { IMarriage } from "../../../../../../Interfaces/IMarriage";
import { MarriageOverview } from "../Components/Marriage/MarriageOverview";
import { getInheritPerson } from "../Components/InheritPerson";
import { IInherit } from "../../../../../../Interfaces/IInherit";

interface IProps {
  handleBack: Function;
  handleNext: Function;
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  setMarriageArray: Function;
}

export const Marriage: React.FC<IProps> = (props) => {
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(-1, props.personArray)
  );
  const [wasMarriad, setWasMarriad] = useState<boolean | null>(
    props.marriageArray.length > 0 ? true : null
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.handleNext();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 5 }} />

        <Fade in={wasMarriad === null} mountOnEnter unmountOnExit>
          <Box
            display="flex"
            height={80}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" sx={{ flaot: "center" }}>
              War der Erblasser / die Erblasserin verheiratet?
              <Box
                display="flex"
                alignItems="right"
                justifyContent="right"
                sx={{ flaot: "right" }}
              >
                <Button onClick={() => setWasMarriad(true)}>Ja</Button>
                <Button onClick={() => props.handleNext()}>Nein</Button>
              </Box>
            </Typography>
          </Box>
        </Fade>

        <Fade
          in={wasMarriad !== null && wasMarriad === true}
          mountOnEnter
          unmountOnExit
        >
          <Box>
            <MarriageOverview
              inheritObject={props.inheritObject}
              currentPerson={currentPerson}
              marriageArray={props.marriageArray}
              personArray={props.personArray}
              personHasDocument={props.personHasDocument}
              setMarriageArray={props.setMarriageArray}
              setPersonArray={props.setPersonArray}
              setPersonHasDocument={props.setPersonHasDocument}
            />
          </Box>
        </Fade>

        <Box sx={{ mt: 10 }} />

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => props.handleBack()}
            sx={{ mr: 1 }}
          >
            Zurück
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            variant="contained"
            disabled={wasMarriad === null}
          >
            Weiter
          </Button>
        </Box>
      </form>
    </>
  );
};
