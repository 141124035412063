import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { IDocument } from "../../../../../../Interfaces/IDocument";
import { DocumentOverview } from "../../../../../Document/DocumentOverview";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";

interface IProps {
  handleBack: Function;
  handleNext: Function;
  mortgageObject: IMortgage;
  setMortgageObject: Function;
  documentArray: IDocument[];
  setDocumentArray: Function;
}

export const MortgageDocuments: React.FC<IProps> = (props) => {
  const { ToBeDeleted, BeneficiaryType } = props.mortgageObject;

  return (
    <>
      <Typography variant="h4" mt={6} mb={6}>Dokument(e)</Typography>
      {BeneficiaryType === "institution" && (
        <>
          <Typography variant="body1">
            Bitte laden Sie das <b>Grundschuldformular</b> und den{" "}
            <b>Notarauftrag</b> hoch.
          </Typography>
        </>
      )}
      {BeneficiaryType === "private" && (
        <>
          <Typography variant="body1">Bitte laden Sie das.</Typography>
        </>
      )}
      {ToBeDeleted && (
        <>
          <Typography variant="body1" mb={2}>
            Bitte laden Sie die Dokumente hoch, die Sie zur Löschung der
            Grundschuld erhalten haben (die sog. <b>Löschungsbewilligung</b>).
          </Typography>
        </>
      )}

      <DocumentOverview
        allowRemove
        allowUpload
        documentArray={props.documentArray}
        setDocumentArray={props.setDocumentArray}
        title=""
      />
      <Box sx={{ mt: 6 }} />
      <Typography sx={{ mb: 2 }}>
        Haben Sie weitere Anmerkungen, Anregungen oder Wünsche zum
        Beurkungungsauftrag?
      </Typography>
      <Grid item xs={6} sm={4}>
        <TextField
          label="Anmerkungen"
          fullWidth
          size="small"
          multiline
          rows={5}
          value={props.mortgageObject.Remarks}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setMortgageObject({
              ...props.mortgageObject,
              Remarks: event.target.value,
            })
          }
        />
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => props.handleBack()}
          sx={{ mr: 1 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          type="submit"
          variant="contained"
          onClick={() => props.handleNext()}
        >
          Weiter
        </Button>
      </Box>
    </>
  );
};
