import React, { useEffect, useState } from "react";
import { IContact, IContactFullObject } from "../../Interfaces/IContact";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import PostcodeSearch from "./PostcodeSearch";
import { IPostcode } from "../../Interfaces/IPostcode";
import { ICompany } from "../../Interfaces/ICompany";
import { genderArray } from "../../StaticArraies/genderArray";
import { businessFormArray } from "../../StaticArraies/businessFormArray";
import { academicTitleArray } from "../../StaticArraies/academicTitleArray";
import { useTranslation } from "react-i18next";
import { IConfig } from "../../Interfaces/IConfig";
import { IQontoWebHook } from "../public/Dialog/Founding/FoundingDialog";

interface IProps {
    configObject: IConfig;
    setIdContact?: Function;
    contactFullObject: IContactFullObject;
    setContactFullObject: Function;
    handleSave: Function;
    handleBack: Function;
    disableHasAccount?: boolean;
    staticEmail?: string;

    fill_object?: IQontoWebHook;

    isTranslationEnabled?: boolean;
}

const handlePostcode = (company: ICompany) => {
  if (
    company.idPostcode != null &&
    company.idPostcode > 0 &&
    company.City != undefined &&
    company.Postcode != undefined
  ) {
    return {
      idPostcode: company.idPostcode,
      City: company.City,
      Postcode: company.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

export const ClientInformation: React.FC<IProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [personObject, setPersonObject] = useState<IContact>(
    props.contactFullObject
  );
  const [companyObject, setCompanyObject] = useState(
    props.contactFullObject.CompanyObject
  );
  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(companyObject));
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);
  //
  const [hasAccount, setHasAccount] = useState(false);

  const [isDsgvoOpen, setIsDsgvoOpen] = React.useState(false);

  const handleDsgvoOpen = () => {
    setIsDsgvoOpen(true);
  };

  const handleDsgvoClose = () => {
    setIsDsgvoOpen(false);
  };

  useEffect(() => {
      if (props.fill_object !== undefined) {
          setPersonObject({
              ...personObject,
              FirstName: props.fill_object.first_name,
              LastName: props.fill_object.last_name,
              Email: props.fill_object.email,
              PhoneNumber: props.fill_object.phone_number,
              viaPartner: props.fill_object.partner
          } as IContact)
      }

  },[props.fill_object])

  useEffect(() => {
    props.setContactFullObject({
      ...personObject,
      CompanyObject: companyObject,
    } as IContactFullObject);
  }, [personObject, companyObject]);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    props.handleSave();
  }

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      setCompanyObject({
        ...companyObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as ICompany);
    }
    setPostcodeObject(postcodeObject);
  };

  const checkTransaltionEnabled = () => {
    if (
      !props.isTranslationEnabled ||
      props.isTranslationEnabled === undefined
    ) {
      return false;
    }
    if (props.isTranslationEnabled) {
      return true;
    }
  };

  return (
    <>
      <Dialog
        open={isDsgvoOpen}
        onClose={handleDsgvoClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Datenschutzerklärung"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              dangerouslySetInnerHTML={{ __html: props.configObject.gdpr_html }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDsgvoClose}>Zurück</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ mt: 15 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        {checkTransaltionEnabled()
          ? t("conclusion_contact_data")
          : "Ihre Kontaktdaten"}

        {(props.disableHasAccount === undefined ||
          props.disableHasAccount === false) && (
          <FormControlLabel
            sx={{ float: "right" }}
            control={
              <Switch
                checked={hasAccount}
                onChange={() => {
                  setHasAccount(!hasAccount);
                }}
                name="gilad"
              />
            }
            label={
              checkTransaltionEnabled()
                ? t("conclusion_dino_account")
                : "Ich habe bereits ein Konto bei DiNo."
            }
          />
        )}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Collapse in={hasAccount}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={
                  checkTransaltionEnabled()
                    ? t("conclusion_account_address")
                    : "Zur Anmeldung hinterlegte E-Mail-Adresse"
                }
                size="small"
                type="email"
                fullWidth
                value={personObject.Email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonObject({
                    ...personObject,
                    Email: event.target.value,
                  })
                }
                required={hasAccount}
              />
            </Grid>
          </Grid>
        </Collapse>

        <Collapse in={!hasAccount}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                label={checkTransaltionEnabled() ? t("titre") : "Titel"}
                size="small"
                fullWidth
                select
                value={personObject.idAcademicTitle}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonObject({
                    ...personObject,
                    idAcademicTitle: Number(event.target.value),
                  })
                }
              >
                {academicTitleArray.map((x) => (
                  <MenuItem
                    key={`idAcademicTitle-${x.idAcademicTitle}`}
                    value={x.idAcademicTitle}
                  >
                    {x.AcademicTitle}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={checkTransaltionEnabled() ? t("first_name") : "Vorname"}
                //wenn boolean true dann t("") benutzen ansonsten "Vorname"
                size="small"
                fullWidth
                value={personObject.FirstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonObject({
                    ...personObject,
                    FirstName: event.target.value,
                  })
                }
                required={!hasAccount}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={checkTransaltionEnabled() ? t("last_name") : "Nachname"}
                size="small"
                fullWidth
                value={personObject.LastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonObject({
                    ...personObject,
                    LastName: event.target.value,
                  })
                }
                required={!hasAccount}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={checkTransaltionEnabled() ? t("Gender") : "Geschlecht"}
                size="small"
                fullWidth
                select
                value={personObject.idGender}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonObject({
                    ...personObject,
                    idGender: Number(event.target.value),
                  })
                }
              >
                {genderArray.map((x) => (
                  <MenuItem key={`idGender-${x.idGender}`} value={x.idGender}>
                    {x.Gender}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={
                  checkTransaltionEnabled()
                    ? t("legal_form")
                    : "Ihre Rechtsform"
                }
                size="small"
                type="number"
                fullWidth
                value={companyObject.idBusinessForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyObject({
                    ...companyObject,
                    idBusinessForm: Number(event.target.value),
                  })
                }
                select
              >
                {businessFormArray.map((x) => (
                  <MenuItem
                    key={`companyObject.idBusinessForm-${x.idBusinessForm}`}
                    value={x.idBusinessForm}
                  >
                    {x.BusinessForm}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={
                  checkTransaltionEnabled() ? t("account_address") : "E-Mail"
                }
                size="small"
                fullWidth
                type="email"
                disabled={props.staticEmail !== undefined}
                value={
                  props.staticEmail === undefined
                    ? personObject.Email
                    : props.staticEmail
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonObject({
                    ...personObject,
                    Email: event.target.value,
                  })
                }
                required={!hasAccount && props.staticEmail === undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={
                  checkTransaltionEnabled()
                    ? t("account_phone_number")
                    : "Telefonnummer"
                }
                size="small"
                required={!hasAccount}
                fullWidth
                value={personObject.PhoneNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonObject({
                    ...personObject,
                    PhoneNumber: event.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={
                  checkTransaltionEnabled() ? t("account_company") : "Firma"
                }
                size="small"
                fullWidth
                value={companyObject.Company}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyObject({
                    ...companyObject,
                    Company: event.target.value,
                  })
                }
                disabled={companyObject.idBusinessForm === 1}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label={checkTransaltionEnabled() ? t("street") : "Straße"}
                size="small"
                required={!hasAccount}
                fullWidth
                value={companyObject.Street}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyObject({
                    ...companyObject,
                    Street: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={checkTransaltionEnabled() ? t("number") : "Hausnummer"}
                size="small"
                required={!hasAccount}
                fullWidth
                value={companyObject.StreetNr}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCompanyObject({
                    ...companyObject,
                    StreetNr: event.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <PostcodeSearch
                  postcodeObject={postcodeObject}
                  setPostcodeObject={setPostcodeObjectWrapper}
                  required={!hasAccount}
                  label={
                    checkTransaltionEnabled() ? t("postcode") : "Postleitzahl"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>

        {/* Alter TExt für die DSGVO
                                <Typography component="span">
                                    Ich bin mit der Verarbeitung meiner Daten zur Interaktion und zum Datenaustausch mit dem Mandantenportal von <i>{props.configObject.company_name}</i> einverstanden.
                                    Die&nbsp;
                                    <Typography
                                        component="span"
                                        onClick={handleDsgvoOpen}
                                        sx={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                                    >
                                        Datenschutzerklärung
                                    </Typography>
                                    &nbsp;von <i>{props.configObject.company_name}</i> habe zur Kenntnis genommen.
                                </Typography>
                **/}

        <Box sx={{ mt: 5 }} />
        <Alert severity="info" icon={false}>
          <FormControlLabel
            label={
              checkTransaltionEnabled() ? (
                t("account_data_dsgvo")
              ) : (
                <Typography component="span">
                  Ich bin mit der Verarbeitung meiner Daten zur Interaktion und
                  zum Datenaustausch mit dem Mandantenportal einverstanden.
                  Die&nbsp;
                  <Typography
                    component="span"
                    onClick={handleDsgvoOpen}
                    sx={{
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Datenschutzerklärung
                  </Typography>
                  &nbsp; habe ich zur Kenntnis genommen.
                </Typography>
              )
            }
            control={
              <Checkbox
                checked={isTermsAndConditionsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setIsTermsAndConditionsChecked(event.target.checked)
                }
                inputProps={{ "aria-label": "controlled" }}
                required
              />
            }
          />
        </Alert>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 15 }}>
          <Button
            variant="outlined"
            onClick={() => props.handleBack()}
            sx={{ mr: 1 }}
          >
            {checkTransaltionEnabled() ? t("button_back") : "Zurück"}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit" variant="contained" onClick={() => {}}>
            {checkTransaltionEnabled() ? t("button_send") : "Senden"}
          </Button>
        </Box>
      </form>
    </>
  );
};
