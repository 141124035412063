import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { IRealEstate } from "../../../../../../../Interfaces/IRealEstate";
import { IPostcode } from "../../../../../../../Interfaces/IPostcode";
import PostcodeSearch from "../../../../../../generic/PostcodeSearch";
import { MapSearch } from "../../../../PurchaseContract/Components/RealEstate/MapSearch";
import { IConfig } from "../../../../../../../Interfaces/IConfig";

interface IProps {
  configObject: IConfig;
  currentObject: IRealEstate;
  setCurrentObject: Function;
  marginTop?: number;
}

export const MortgageRealEstateAddress: React.FC<IProps> = (props) => {
  const [mapValue, setMapValue] = useState<string | null>("false");
  const handlePostcode = (localObject: IRealEstate) => {
    if (
      localObject.idPostcode != null &&
      localObject.idPostcode > 0 &&
      localObject.City !== undefined &&
      localObject.Postcode !== undefined
    ) {
      return {
        idPostcode: localObject.idPostcode,
        City: localObject.City,
        Postcode: localObject.Postcode,
        idCountry: 1,
      } as IPostcode;
    } else {
      return null;
    }
  };

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject) {
      props.setCurrentObject({
        ...props.currentObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as IRealEstate);
    }
    setPostcodeObject(postcodeObject);
  };

  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(props.currentObject));

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapValue(event.target.value);
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 2 }}
      >
        Anschrift des Objektes
      </Typography>
      <Typography mb={2}>
        Sie müssen entweder die Adresse oder die Koordinaten des Objektes
        angeben. Auch beide Informationen können eingetragen werden.
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={5}>
          <TextField
            label="Straße"
            size="small"
            required
            fullWidth
            value={
              props.currentObject.Street === null
                ? ""
                : props.currentObject.Street
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Street: event.target.value === "" ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={5}>
          <TextField
            label="Adresszusatz"
            size="small"
            fullWidth
            value={
              props.currentObject.StreetAditional === null
                ? ""
                : props.currentObject.StreetAditional
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                StreetAditional:
                  event.target.value === "" ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label="Hausnummer"
            size="small"
            required
            fullWidth
            value={
              props.currentObject.StreetNr === null
                ? ""
                : props.currentObject.StreetNr
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                StreetNr: event.target.value === "" ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={12}>
          <PostcodeSearch
            postcodeObject={postcodeObject}
            setPostcodeObject={setPostcodeObjectWrapper}
            required
          />
        </Grid>

        <Grid item sm={12}>
          <Box sx={{ mt: 1 }} />
        </Grid>

        {/*<Grid item sm={6}>
          <NumericFormat
            label="Breitengrad"
            size="small"
            suffix="°"
            fixedDecimalScale
            decimalSeparator=","
            fullWidth
            required={
              props.currentObject.Street === "" ||
              props.currentObject.Street === null ||
              props.currentObject.StreetNr === "" ||
              props.currentObject.StreetNr === null ||
              props.currentObject.Postcode === null ||
              props.currentObject.Postcode === ""
            }
            value={
              props.currentObject.Latitude === null
                ? null
                : props.currentObject.Latitude
            }
            customInput={TextField}
            inputRef={inputRef} // Referenz zum Textfeld
            onValueChange={(values) => {
              const { floatValue } = values;
              const cursorPosition = inputRef.current?.selectionStart;
              props.setCurrentObject({
                ...props.currentObject,
                Latitude: floatValue !== undefined ? floatValue : null,
              });
              // Nach dem Setzen des Werts die Cursor-Position wiederherstellen
              setTimeout(() => {
                if (inputRef.current && cursorPosition) {
                  inputRef.current.setSelectionRange(
                    cursorPosition,
                    cursorPosition
                  );
                }
              }, 0); // Die Wiederherstellung erfolgt nach einem Render-Zyklus
            }}
            isAllowed={(values) => {
              const { floatValue } = values;

              if (floatValue && (floatValue >= 100 || floatValue <= -100)) {
                return false;
              }
              return true;
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <NumericFormat
            label="Längengrad"
            size="small"
            suffix="°"
            fixedDecimalScale
            decimalSeparator=","
            fullWidth
            required={
              props.currentObject.Street === "" ||
              props.currentObject.Street === null ||
              props.currentObject.StreetNr === "" ||
              props.currentObject.StreetNr === null ||
              props.currentObject.Postcode === null ||
              props.currentObject.Postcode === ""
            }
            value={
              props.currentObject.Longitude === null
                ? null
                : props.currentObject.Longitude
            }
            customInput={TextField}
            inputRef={inputRef} // Referenz zum Textfeld
            onValueChange={(values) => {
              const { floatValue } = values;
              const cursorPosition = inputRef.current?.selectionStart;
              props.setCurrentObject({
                ...props.currentObject,
                Longitude: floatValue !== undefined ? floatValue : null,
              });
              // Nach dem Setzen des Werts die Cursor-Position wiederherstellen
              setTimeout(() => {
                if (inputRef.current && cursorPosition) {
                  inputRef.current.setSelectionRange(
                    cursorPosition,
                    cursorPosition
                  );
                }
              }, 0); // Die Wiederherstellung erfolgt nach einem Render-Zyklus
            }}
            isAllowed={(values) => {
              const { floatValue } = values;

              if (floatValue && (floatValue >= 100 || floatValue <= -100)) {
                return false;
              }
              return true;
            }}
          />
        </Grid>*/}

        <Grid item sm={12}>
          <Box sx={{ mt: 1 }} />
        </Grid>
        <Box ml={2} mt={2}>
          <Typography sx={{ mb: 2 }}>Karte anzeigen?</Typography>

          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="property-owned"
              name="property-owned"
              value={mapValue}
              onChange={handleToggle}
            >
              <FormControlLabel value="true" control={<Radio />} label="Ja" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Nein"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        {mapValue === "true" && (
          <Grid item sm={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Adresse / Koordinaten suchen</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <MapSearch
                  configObject={props.configObject}
                  realEstate={props.currentObject}
                  setRealEstate={props.setCurrentObject}
                  setPostcodeObject={setPostcodeObject}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </>
  );
};
