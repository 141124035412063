import React, { useState, useEffect } from "react";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import { IPerson } from "../../../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import {IMortgage, IMortgageFullObject} from "../../../../../../../Interfaces/IMortgage";
import { genderArray } from "../../../../../../../StaticArraies/genderArray";
import { academicTitleArray } from "../../../../../../../StaticArraies/academicTitleArray";
import { staticCountryArray } from "../../../../../../../StaticArraies/staticCountryArray";
import {GridBaseData} from "../../../../../../Person/GridBaseData";

interface IProps {
    currentPerson: IPerson;
    setCurrentPerson: Function;

    mortgageObject: IMortgage;
    setMortgageObject?: Function;

    personArray : IPerson[]
    setPersonArray : Function

    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;

    isDisabled: boolean;
    setIsDisabled: Function;
}

export const getMortgagePerson = (idPerson: number) => {
    return {
        idPerson: idPerson,
        idGender: 4,
        idAcademicTitle: 1,
        idPostcode: -1,
        idCountry: 1,
        FirstName: "",
        LastName: "",
        Surname: null,
        idBusinessForm: 1,
        Email: null,
        PhoneNumber: null,

        Postcode: "",
        City: "",
        Street: "",
        StreetAditional: "",
        StreetNr: "",

        TaxNumber: null,
        IBAN: null,
        BIC: null,
        Bank: null,
       

    } as IPerson
}

export const MortgagePerson: React.FC<IProps> = (props) => {
    const [isOpenWarning, setIsOpenWarning] = useState(false);
    const [isMinimalRequirementActiv, setIsMinimalRequirementActiv] = useState(false);

    useEffect(() => {
        const { idGender, FirstName, LastName, Birthday } = props.currentPerson;
        const allFieldsFilled = idGender && FirstName && LastName && Birthday;
        props.setIsDisabled(!allFieldsFilled);
    }, [props.currentPerson, props.setIsDisabled]);

    return (
        <>
            {(props.mortgageObject.idPerson !== props.currentPerson.idPerson) &&
                <Dialog
                    open={isOpenWarning}
                    onClose={() => setIsOpenWarning(false)}
                >
                    <DialogContent>
                        <Alert severity="warning">
                            Sobald Sie diese Option aktiveren, sind nur noch die Felder <i>Geschlecht</i>, <i>Vorname</i> und <i>Nachname</i> pflicht.
                            <br />Wir weisen dringlichst darauf hin, dass Sie diese Option nur im Sonderfall aktivieren sollten,
                            um eine schnelle und reibungslose Abwicklung zu gewährleisetn.
                        </Alert>
                        <Box sx={{ mt: 2 }} />
                        Sollen die Pflichtfelder aktiviert oder deaktiviert werden?
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" onClick={() => { setIsMinimalRequirementActiv(true); setIsOpenWarning(false) }}>Deaktivieren</Button>
                        <Button variant="contained" onClick={() => { setIsMinimalRequirementActiv(false); setIsOpenWarning(false) }}>Aktivieren</Button>
                    </DialogActions>
                </Dialog>
            }

            <Typography variant="h5" sx={{ marginBottom: 3 }}>Eigentümerdaten</Typography>

            <GridBaseData
                personObject={props.currentPerson}
                personArray={props.personArray}
                setPersonObject={props.setCurrentPerson}
                showDeathSettings={false}
                forceDeathSettings={false}
                titleAdress="Anschrift"
                setIsDeath={() => {
                }}
                disableEdit={false}
                forceAdress={true}
                disableDeathDateWarning={true}
                disableContactInformations={true}
            />

            {/*


            <Grid container spacing={2}>
                <Grid item xs={6} sm={2}>
                    <TextField
                        label={"Geschlecht"}
                        size="small"
                        fullWidth
                        required
                        select
                        value={(props.currentPerson.idGender < 0) ? "" : props.currentPerson.idGender}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, idGender: Number(event.target.value) })}
                    >
                        {genderArray.map(x =>
                            <MenuItem key={`idGender-${x.idGender}`} value={x.idGender}>{x.Gender}</MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField
                        label="Titel"
                        size="small"
                        fullWidth
                        select
                        value={props.currentPerson.idAcademicTitle}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, idAcademicTitle: Number(event.target.value) })}
                    >
                        {academicTitleArray.map(x =>
                            <MenuItem key={`idAcademicTitle-${x.idAcademicTitle}`} value={x.idAcademicTitle}>{x.AcademicTitle}</MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField
                        label="Vorname"
                        size="small"
                        fullWidth
                        value={props.currentPerson.FirstName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, FirstName: event.target.value })}
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField
                        label="Nachname"
                        size="small"
                        fullWidth
                        value={props.currentPerson.LastName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, LastName: event.target.value })}
                        required
                    />
                </Grid>

                <Grid item sm={6}>
                    <TextField
                        label="Nationalität"
                        size="small"
                        fullWidth
                        select
                        value={props.currentPerson.idCountry === null ? 1 : props.currentPerson.idCountry}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, idCountry: Number(event.target.value) })}
                    >
                        {staticCountryArray.map(x =>
                            <MenuItem key={`idCountry-${x.idCountry}`} value={x.idCountry}>{x.Nationality}</MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        label="Geburtsname"
                        size="small"
                        fullWidth
                        value={(props.currentPerson.Surname === null) ? "" : props.currentPerson.Surname}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, Surname: (event.target.value === "") ? null : event.target.value })}
                    />
                </Grid>

                <Grid item sm={6}>
                    <TextField
                        label="Geburtstag"
                        size="small"
                        type="date"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        value={props.currentPerson.Birthday}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, Birthday: event.target.value })}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        label="Geburtsort"
                        size="small"
                        fullWidth
                        value={(props.currentPerson.Birthplace === null) ? "" : props.currentPerson.Birthplace}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCurrentPerson({ ...props.currentPerson, Birthplace: (event.target.value === "") ? null : event.target.value })}
                    />
                </Grid>

            </Grid>
          */}
        </>
    )
}
