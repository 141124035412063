import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";
import { IPerson } from "../../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../../Interfaces/IPersonHasDocument";
import { MortgageRealEstateAddress } from "../Components/RealEstate/MortgageRealEstateAddress";
import { IConfig } from "../../../../../../Interfaces/IConfig";
import { RealEstateLandRegister } from "../../../PurchaseContract/Components/RealEstate/RealEstateLandRegister";

interface IProps {
  configObject: IConfig;
  handleBack: Function;
  handleNext: Function;
  mortgageObject: IMortgage;
  setMortgageObject: Function;
  personArray: IPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

export const MortgageOptions: React.FC<IProps> = (props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [existMortgage, setExistMortgage] = useState<boolean | null>(
    props.mortgageObject.ToBeDeleted
  );
  const [hasAddress, setHasAddress] = useState(
    props.mortgageObject.RealEstateLandRegistryData
      ? props.mortgageObject.RealEstateLandRegistryData
      : null
  );
  const [currentObject, setCurrentObject] = useState(
    props.mortgageObject.RealEstate
  );

  useEffect(() => {
    props.setMortgageObject({
      ...props.mortgageObject,
      ToBeDeleted: existMortgage,
    });
  }, [existMortgage]);

  useEffect(() => {
    props.setMortgageObject({
      ...props.mortgageObject,
      RealEstate: currentObject,
      RealEstateLandRegistryData: hasAddress,
    });
  }, [currentObject, hasAddress]);

  const handleSubmit = () => {
    props.setMortgageObject({
      ...props.mortgageObject,
      RealEstate: currentObject,
    });
    props.handleNext();
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSubmit();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasAddress(event.target.value);
    if (event.target.value === "no") {
      setCurrentObject({
        ...currentObject,
        LandRegister: "",
        Court: undefined,
        LandParcel: "",
        Parcel: "",
        SheetLandRegister: "",
        idCourt: null,
      });
    } else if (event.target.value === "yes") {
      setCurrentObject({
        ...currentObject,
        City: "",
        Postcode: "",
        Street: "",
        StreetNr: "",
        StreetAditional: "",
        Latitude: null,
        Longitude: null,
        idPostcode: null,
      });
    }
  };

  const isFormValid = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <form ref={formRef}>
        <Box sx={{ mt: 6 }} />
        <Typography variant="h4">Bestellen / Löschen</Typography>
        <Box sx={{ mt: 6 }} />
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" sx={{ mb: 2 }}>
            Soll eine bereits im Grundbuch eingetragene Grundschuld gelöscht
            (Option 1) oder eine neue Grundschuld im Grundbuch eingetragen
            (Option 2) werden?
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={
                existMortgage === null ? "" : existMortgage ? "delete" : "new"
              }
              onChange={(e) => setExistMortgage(e.target.value === "delete")}
            >
              <Alert severity={"info"} icon={false} sx={{ mb: 2 }}>
                <FormControlLabel
                  value="delete"
                  control={<Radio required />}
                  label={
                    <Typography component="span">
                      <u>
                        <b>Option 1:</b>
                      </u>{" "}
                      Es soll eine bereits im Grundbuch eingetragene Grundschuld
                      gelöscht werden.
                    </Typography>
                  }
                />
              </Alert>
              <Alert severity={"info"} icon={false}>
                <FormControlLabel
                  value="new"
                  control={<Radio required />}
                  label={
                    <Typography component="span">
                      <u>
                        <b>Option 2:</b>
                      </u>{" "}
                      Es soll eine neue Grundschuld bestellt und im Grundbuch
                      eingetragen werden.
                    </Typography>
                  }
                />
              </Alert>
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ mt: 6 }} />
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Geben Sie die Grundbuchdaten Ihres Grundstücks an, das mit der
            Grundschuld belastet ist bzw. werden soll. Falls Ihnen diese Daten
            nicht vorliegen geben Sie bitte die Adresse des Grundstücks ein.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Liegen Grundbuchdaten vor?
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="property-owned"
              name="property-owned"
              value={hasAddress}
              onChange={handleToggle}
            >
              <FormControlLabel
                value="yes"
                control={<Radio required />}
                label="Ja"
              />
              <FormControlLabel
                value="no"
                control={<Radio required />}
                label="Nein"
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 5 }} />
          {hasAddress === "yes" && (
            <RealEstateLandRegister
              currentObject={currentObject}
              setCurrentObject={setCurrentObject}
            />
          )}
          {hasAddress === "no" && (
            <MortgageRealEstateAddress
              configObject={props.configObject}
              currentObject={currentObject}
              setCurrentObject={setCurrentObject}
            />
          )}
        </Box>
        <Box sx={{ mt: 10 }} />
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => props.handleBack()}
            sx={{ mr: 1 }}
          >
            Zurück
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={() => {
              handleOnSave();
            }}
            variant={isFormValid() ? "contained" : "outlined"}
          >
            Weiter
          </Button>
        </Box>
      </form>
    </>
  );
};
