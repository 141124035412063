import React, { useState } from "react";
import { IRealEstate } from "../../../../../../Interfaces/IRealEstate";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { RealEstateSummary } from "../Components/Summary/RealEstateSummary";
import { IDocument } from "../../../../../../Interfaces/IDocument";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";
import { CustomCircularProgress } from "../../../../../generic/CustomCircularProgress";
import { IConfig } from "../../../../../../Interfaces/IConfig";
import { IPerson } from "../../../../../../Interfaces/IPerson";
import { MortgageDocumentSummary } from "../Components/Summary/MortgageDocumentSummary";
import { Search } from "@mui/icons-material";
import { MortgagePerson } from "../Components/Owner/MortgagePerson";
import { checkString } from "../../../../../core/standardFunctions";
interface IProps {
  configObject: IConfig;
  handleBack: Function;
  handleNext: Function;
  realEstate: IRealEstate;
  personArray: IPerson[];
  documentArray: IDocument[];
  mortgageObject: IMortgage;
  setMortgageObject: Function;
}

export const MortgageSummary: React.FC<IProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState<number | null>(null);

  if (isLoading) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Zusammenfassung
        </Typography>

        <Typography variant="h6" sx={{ mt: 5 }}>
          Eigentümer
        </Typography>

        <Dialog
          open={index !== null}
          maxWidth="md"
          fullWidth
          onClose={() => setIndex(null)}
        >
          <DialogContent>
            <Box sx={{ mt: 2 }} />
            {index !== null && (
              <MortgagePerson
                personArray={props.personArray}
                setPersonArray={() => {}}
                currentPerson={props.personArray[index]}
                setCurrentPerson={() => {}}
                mortgageObject={props.mortgageObject}
                setMortgageObject={props.setMortgageObject}
                setPersonHasDocument={() => {}}
                setIsDisabled={() => {}}
                isDisabled={false}
                personHasDocument={[]}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setIndex(null);
              }}
            >
              Abbruch
            </Button>
          </DialogActions>
        </Dialog>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={"20%"}>Nr.:</TableCell>
              <TableCell width={"20%"}>Vorname</TableCell>
              <TableCell width={"20%"}>Nachname</TableCell>
              <TableCell width={"20%"}>Geburtstag</TableCell>
              <TableCell width={"20%"}>Aktionen</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.personArray.map((x, i) => (
              <TableRow>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{x.FirstName}</TableCell>
                <TableCell>{x.LastName}</TableCell>
                <TableCell>
                  {x.Birthday ? x.Birthday.split("-").reverse().join(".") : ""}
                </TableCell>
                <TableCell>
                  {
                    <IconButton
                      onClick={() => {
                        setIndex(i);
                      }}
                    >
                      <Search />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <RealEstateSummary mortgageObject={props.mortgageObject} />

        <Grid container spacing={2} sx={{ ml: 0, mt: 5 }}>
          <>
            <Typography variant="h6">Details zur Grundschuld:</Typography>
            <Grid item sm={12}>
              <hr />
            </Grid>

            <Grid item sm={4}>
              <b>Neue bzw. zu löschende Grundschuld?</b>
            </Grid>
            <Grid item sm={8}>
              {props.mortgageObject.ToBeDeleted ? "Zu löschen" : "Neu"}
            </Grid>

            <Grid item sm={4}>
              <b>
                Wird die Grundschuld zur Finanzierung eines Immobilienkaufs
                benötigt?
              </b>
            </Grid>
            <Grid item sm={8}>
              {props.mortgageObject.PropertyForBuying ? "Ja" : "Nein"}
            </Grid>

            {props.mortgageObject.PropertyForBuying && (
              <>
                <Grid item sm={4}>
                  <b>Ist der Immobilienkauf bereits erfolgt?</b>
                </Grid>
                <Grid item sm={8}>
                  {props.mortgageObject.PropertyOwned ? "Ja" : "Nein"}
                </Grid>
                {props.mortgageObject.PropertyOwned && (
                  <>
                    <Grid item sm={4}>
                      <b>Urkundenverzeichnisnummer:</b>
                    </Grid>
                    <Grid item sm={8}>
                      {checkString(props.mortgageObject.DocumentIndexNumber)}
                    </Grid>

                    <Grid item sm={4}>
                      <b>Vorgangsnummer:</b>
                    </Grid>
                    <Grid item sm={8}>
                      {checkString(props.mortgageObject.OperationNumber)}
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid item sm={4}>
              <b>Betrag der Grundschuld:</b>
            </Grid>
            <Grid item sm={8}>
              {props.mortgageObject.AmountMortgage !== null
                ? props.mortgageObject.AmountMortgage.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })
                : "0"}
            </Grid>

            <Grid item sm={4}>
              <b>Grundschuldzinssatz:</b>
            </Grid>
            <Grid item sm={8}>
              {props.mortgageObject.MortgageInterest !== null
                ? props.mortgageObject.MortgageInterest.toString()
                : "0"}
              {" %"}
            </Grid>

            <Grid item sm={4}>
              <b>Nebenleistungen?</b>
            </Grid>
            <Grid item sm={8}>
              {props.mortgageObject.AdditionalServices || "Keine"}
            </Grid>

            <Grid item sm={4}>
              <b>Abschriften des Vertrages:</b>
            </Grid>
            <Grid item sm={8}>
              {props.mortgageObject.Transcripts?.map((transcript: string) => {
                switch (transcript) {
                  case "simple_transcription":
                    return "Einfache Abschrift";

                  case "certified_copy":
                    return "Beglaubigte Abschrift";

                  case "simple_execution":
                    return "Einfache Ausfertigung";

                  case "enforceable_copy":
                    return "Vollstreckbare Ausfertigung";
                  default:
                    return "Keine";
                }
              }).join(", ") || "Keine"}
            </Grid>

            <Grid item sm={4}>
              <b>Grundschuld nach § 873 Abs. 2 BGB entgegengenommen?</b>
            </Grid>
            <Grid item sm={8}>
              {props.mortgageObject.Paragraph ? "Ja" : "Nein"}
            </Grid>
          </>
        </Grid>

        <MortgageDocumentSummary documentArray={props.documentArray} />

        <Grid container spacing={2} sx={{ ml: 0, mt: 5 }}>
          <Grid item sm={4}>
            <b>Anmerkungen :</b>
          </Grid>
          <Grid item sm={8}>
            {props.mortgageObject.Remarks && props.mortgageObject.Remarks.length
              ? props.mortgageObject.Remarks
              : "Keine"}
          </Grid>
        </Grid>
      </>
    );
  }
};
