import React, {useEffect, useState} from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { IPerson} from "../../../../../../../Interfaces/IPerson";
import {IMortgage, IMortgageFullObject} from "../../../../../../../Interfaces/IMortgage";
import { Delete, Edit } from "@mui/icons-material";
import {MortgagePerson} from "./MortgagePerson";



interface IProps {
    mortgageObject: IMortgage;
    setMortgageObject: Function;
    personArray: IPerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
}



export const MortgageOwnerRow:React.FC<IProps> = (props) => {
    const [editOwner, setEditOwner] = useState<IPerson>()
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false)

    const handleRemove = (id: number) => {
        const updatedPersonArray = props.personArray.filter((person) => person.idPerson !== id);
        props.setPersonArray(updatedPersonArray);
    };

    const handleOpen = (person: IPerson) => {
        setEditOwner(person);
        setIsEditOpen(true);
    };

     const updateOwner = () => {
        if (editOwner) {
            const updatedPersonArray = props.personArray.map((person) =>
                person.idPerson === editOwner.idPerson ? editOwner : person
            );
            props.setPersonArray(updatedPersonArray);
            setIsEditOpen(false);
        }
    };

  return (
    <>
        {props.personArray && props.personArray.map((x, index) => (
            <TableRow key={x.idPerson} sx={{ backgroundColor: (x.idPerson === 1) ? "#f2f2f2" : undefined }}>
                <TableCell width={"25%"}>{index + 1}</TableCell>
                <TableCell width={"25%"}>
                    {x.LastName}
                </TableCell>
                <TableCell width={"25%"}>
                    {x.FirstName}
                </TableCell>
                <TableCell width={"25%"}>
                    <IconButton onClick={() => handleOpen(x)}><Edit /></IconButton>
                    <IconButton onClick={() => handleRemove(x.idPerson)}><Delete /></IconButton>
                </TableCell>
            </TableRow>
        ))}
            <Dialog
                open={isEditOpen}
                maxWidth="md"
                fullWidth
                onClose={() => setIsEditOpen(false)}
            >
                <DialogContent>
                    <Box sx={{mt: 2}}/>
                    {editOwner && (
                        <MortgagePerson
                            personArray={props.personArray}
                            setPersonArray={props.setPersonArray}
                            currentPerson={editOwner}
                            setCurrentPerson={setEditOwner}
                            mortgageObject={props.mortgageObject}
                            setMortgageObject={props.setMortgageObject}
                            personHasDocument={props.personHasDocument}
                            setPersonHasDocument={props.setPersonHasDocument}
                            isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => {setIsEditOpen(false)}}>Abbruch</Button>
                    <Button variant="contained" onClick={() => {updateOwner()}}>Spreichern</Button>
                </DialogActions>
            </Dialog>
    </>
)}
