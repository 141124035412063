import React from "react";
import { ICompanyFormationShare } from "../../../../../../../Interfaces/ICompanyFormationShare";
import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { Delete, PublishedWithChanges } from "@mui/icons-material";
import { roundFunctionNormal } from "../../help_functions";

interface IProps {
  index: number;
  shareObject: ICompanyFormationShare;
  companyFormationShareArray: ICompanyFormationShare[];
  setCompanyFormationShareArray: Function;
  totalValue: number;
}

export const SharesChildRow: React.FC<IProps> = (props) => {
  const setObject = (currentObject: ICompanyFormationShare) => {
    props.setCompanyFormationShareArray([
      ...props.companyFormationShareArray.map((x) =>
        x.idCompanyFormationShare === currentObject.idCompanyFormationShare
          ? currentObject
          : x
      ),
    ]);
  };

  const handleChangeAmountValues = (values: any, sourceInfo: any) => {
    const value = roundFunctionNormal(values.floatValue);
    let valuePerShare = props.shareObject.ValuePerShare;

    if (props.companyFormationShareArray.length === 1) {
      valuePerShare = props.totalValue / value;
    }

    setObject({
      ...props.shareObject,
      AmountOfShares: value,
      ValuePerShare: valuePerShare,
    });
  };

  const handleChangeValuePerShare = (values: any, sourceInfo: any) => {
    const valuePerShare = values.floatValue;
    let amountShares = roundFunctionNormal(props.shareObject.AmountOfShares);

    if (props.companyFormationShareArray.length === 1) {
      amountShares = roundFunctionNormal(props.totalValue / valuePerShare);
    }

    setObject({
      ...props.shareObject,
      AmountOfShares: amountShares,
      ValuePerShare: valuePerShare,
    });
  };

  const updateAllValuesPerShare = () => {
    const tmpArray: ICompanyFormationShare[] = [];

    props.companyFormationShareArray.map((x) => {
      const totalAmountShares = props.companyFormationShareArray
        .map((x) => x.AmountOfShares)
        .reduce((a, b) => a + b, 0);
      const prozValue = x.AmountOfShares / totalAmountShares;
      const totalValue =
        Math.round(((props.totalValue * prozValue) / x.AmountOfShares) * 100) /
        100;
      tmpArray.push({
        ...x,
        ValuePerShare: totalValue,
      } as ICompanyFormationShare);
    });

    props.setCompanyFormationShareArray([...tmpArray]);
  };

  const removeMe = () => {
    props.setCompanyFormationShareArray([
      ...props.companyFormationShareArray.filter(
        (x) =>
          x.idCompanyFormationShare !==
          props.shareObject.idCompanyFormationShare
      ),
    ]);
  };

  return (
    <>
      <TableRow>
        {props.index === 0 && (
          <TableCell
            colSpan={2}
            rowSpan={props.companyFormationShareArray.length}
          >
            {false && props.companyFormationShareArray.length > 1 && (
              <IconButton
                sx={{ float: "right" }}
                title="Alle Werte pro Anteile durch die Anzahl der Anteile ableiten."
                onClick={updateAllValuesPerShare}
              >
                <PublishedWithChanges />
              </IconButton>
            )}
          </TableCell>
        )}

        <TableCell>
          <NumericFormat
            value={props.shareObject.ValuePerShare}
            suffix=" €"
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalSeparator=","
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label="Wert pro Anteil"
            size="small"
            onValueChange={handleChangeValuePerShare}
            error={props.shareObject.ValuePerShare % 1 !== 0}
          />
        </TableCell>
        <TableCell>
          <NumericFormat
            value={props.shareObject.AmountOfShares}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalSeparator=","
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label="Anteile"
            size="small"
            onValueChange={handleChangeAmountValues}
          />
        </TableCell>
        <TableCell>
          <NumericFormat
            value={
              props.shareObject.ValuePerShare * props.shareObject.AmountOfShares
            }
            suffix=" €"
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalSeparator=","
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label="Nennbetrag Anteil(e)"
            size="small"
            disabled
          />
        </TableCell>

        <TableCell>
          <IconButton onClick={removeMe} disabled={props.index === 0}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
