import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGender } from "../../Interfaces/IGender";

const initialState = { storeGender: [] as IGender[] };

const sliceGender = createSlice({
  name: "GENDERARRAY",
  initialState,
  reducers: {
    setGenderArray: (state, action: PayloadAction<IGender[]>) => {
      state.storeGender = [...action.payload];
    },
    addUpdateGenderArray: (state, action: PayloadAction<IGender>) => {
      const testObject = state.storeGender.find(
        (x) => x.idGender === action.payload.idGender
      );

      if (testObject) {
        state.storeGender = [
          ...state.storeGender.map((x) =>
            x.idGender === action.payload.idGender ? action.payload : x
          ),
        ];
      } else {
        state.storeGender = [action.payload, ...state.storeGender];
      }
    },
  },
});
export const { setGenderArray, addUpdateGenderArray } = sliceGender.actions;
export default sliceGender.reducer;
