import React, { useState } from "react";
import { IRealEstate } from "../../../../../Interfaces/IRealEstate";
import { IRealEstateHasPropertyType } from "../../../../../Interfaces/IRealEstateHasPropertyType";
import { IPropertyType } from "../../../../../Interfaces/IPropertyType";
import { IRealEstateHasInventoryOption } from "../../../../../Interfaces/IRealEstateHasInventoryOption";
import { IInventoryOption } from "../../../../../Interfaces/IInventoryOption";
import { IRealEstateUseType } from "../../../../../Interfaces/IRealEstateUseType";
import { IPurchaseContractHasPerson } from "../../../../../Interfaces/IPerson";
import {
  Box,
  Collapse,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { RealEstateSummary } from "../Components/Summary/RealEstateSummary";
import { PurchaseContractRelationSummary } from "../Components/Summary/PurchaseContractRelationSummary";
import {
  IContact,
  IContactFullObject,
} from "../../../../../Interfaces/IContact";
import { ICompany } from "../../../../../Interfaces/ICompany";
import { ClientInformation } from "../../../../generic/ClientInformation";
import { PurchaseContractDocumentSummary } from "../Components/Summary/PurchaseContractDocumentSummary";
import { IDocument } from "../../../../../Interfaces/IDocument";
import {
  IPurchaseContract,
  IPurchaseContractFullObject,
} from "../../../../../Interfaces/IPurchaseContract";
import { uploadFetch } from "../../../../../hooks/useFetch";
import { CustomCircularProgress } from "../../../../generic/CustomCircularProgress";
import { IConfig } from "../../../../../Interfaces/IConfig";

interface IProps {
  configObject: IConfig;
  handleBack: Function;
  handleNext: Function;
  realEstateArray: IRealEstate[];
  realEstateHasPropertyTypeArray: IRealEstateHasPropertyType[];
  propertyTypeArray: IPropertyType[];
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  inventoryOptionArray: IInventoryOption[];
  realEstateUseTypeArray: IRealEstateUseType[];
  personArray: IPurchaseContractHasPerson[];
  documentArray: IDocument[];
  purchaseContractEdit: IPurchaseContract;
  setPurchaseContractEdit: Function;
}

export const PurchaseContractSummary: React.FC<IProps> = (props) => {
  const [withThirdPartyClause, setWithThirdPartyClause] = useState(false);
  const [thirdPartyClauseText, setThirdPartyClauseText] = useState("");
  //
  const [isLoading, setIsLoading] = useState(false);

  const [personObject, setPersonObject] = useState<IContact>({
    idContact: -1,
    idCompany: -1,
    idGender: 1,
    idAcademicTitle: 1,
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
  } as IContact);

  const [companyObject, setCompanyObject] = useState<ICompany>({
    idCompany: -1,
    Company: "",
    idBusinessForm: 1,
    idPostcode: null,
    Street: "",
    StreetNr: "",
    StreetAditional: "",
    PhoneNumber: "",
    Website: "",
    RegisterCourt: null,
    RegisterNumber: null,
    TaxNumber: null,
    SalesTaxNumber: null,
  });

  const handleSubmit = () => {
    const uploadObject = {
      ...props.purchaseContractEdit,
      PersonArray: props.personArray,
      DocumentArray: props.documentArray,
      RealEstateArray: props.realEstateArray,
      RealEstateHasInventoryOptionArray:
        props.realEstateHasInventroyOptionArray,
      RealEstateHasPropertyTypeArray: props.realEstateHasPropertyTypeArray,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
      } as IContactFullObject,
    } as IPurchaseContractFullObject;

    setIsLoading(true);
    uploadFetch(
      "/legaltransaction/createPurchaseContract",
      true,
      uploadObject,
      () => {},
      () => {},
      () => {
        setIsLoading(false);
        props.handleNext();
      }
    );
  };

  const generatePDF = () => {
    const uploadObject = {
      ...props.purchaseContractEdit,
      PersonArray: props.personArray,
      DocumentArray: props.documentArray,
      RealEstateArray: props.realEstateArray,
      RealEstateHasInventoryOptionArray:
        props.realEstateHasInventroyOptionArray,
      RealEstateHasPropertyTypeArray: props.realEstateHasPropertyTypeArray,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
      } as IContactFullObject,
    } as IPurchaseContractFullObject;
    uploadFetch(
      "/legaltransaction/createPdf",
      true,
      uploadObject,
      () => {},
      () => {},
      () => {}
    );
  };

  if (isLoading) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Box sx={{ mt: 5 }} />
        <Typography variant="h4" sx={{ mb: 3 }}>
          Abschluss
        </Typography>

        <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
          Maklerklausel
        </Typography>
        <TextField
          label="Marklerklausel"
          value={
            props.purchaseContractEdit.withThirdPartyClause ? "true" : "false"
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setPurchaseContractEdit({
              ...props.purchaseContractEdit,
              withThirdPartyClause: event.target.value === "true",
              ThirdPartyClauseText: null,
            })
          }
          fullWidth
          size="small"
          select
        >
          <MenuItem key="withThirdPartyClause-true" value="true">
            Ja
          </MenuItem>
          <MenuItem key="withThirdPartyClause-false" value="false">
            Nein
          </MenuItem>
        </TextField>
        <Collapse in={props.purchaseContractEdit.withThirdPartyClause}>
          <TextField
            sx={{ mt: 2 }}
            label="Maklerklausel"
            value={
              props.purchaseContractEdit.ThirdPartyClauseText === null
                ? ""
                : props.purchaseContractEdit.ThirdPartyClauseText
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setPurchaseContractEdit({
                ...props.purchaseContractEdit,
                ThirdPartyClauseText:
                  event.target.value === "" ? null : event.target.value,
              })
            }
            fullWidth
            size="small"
            multiline
            rows={5}
          />
        </Collapse>

        <Box sx={{ mt: 5 }} />
        <RealEstateSummary
          realEstateArray={props.realEstateArray}
          realEstateHasPropertyTypeArray={props.realEstateHasPropertyTypeArray}
          realEstateHasInventroyOptionArray={
            props.realEstateHasInventroyOptionArray
          }
          propertyTypeArray={props.propertyTypeArray}
          inventoryOptionArray={props.inventoryOptionArray}
        />

        <PurchaseContractRelationSummary
          title="Verkäufer/in"
          idPruchaseContractRelationType={1}
          personArray={props.personArray}
        />

        <PurchaseContractRelationSummary
          title="Käufer/in"
          idPruchaseContractRelationType={5}
          personArray={props.personArray}
        />

        <PurchaseContractDocumentSummary documentArray={props.documentArray} />

        <ClientInformation
          configObject={props.configObject}
          disableHasAccount={
            props.configObject.Module.find((x) => x === "purchase_contract") ===
            undefined
          }
          contactFullObject={
            {
              ...personObject,
              CompanyObject: companyObject,
            } as IContactFullObject
          }
          setContactFullObject={(localFullObject: IContactFullObject) => {
            setPersonObject(localFullObject);
            setCompanyObject(localFullObject.CompanyObject);
          }}
          handleBack={() => props.handleBack()}
          handleSave={handleSubmit}
          isTranslationEnabled={false}
        />
      </>
    );
  }
};
