import React, { useState } from "react";

import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Search } from "@mui/icons-material";
import { ICompany } from "../../../../Interfaces/ICompany";
import {
  ICompanyFormation,
  ICompanyFormationFullObject,
} from "../../../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../../../Interfaces/IShareHolder";
import { ICompanyFormationShare } from "../../../../Interfaces/ICompanyFormationShare";
import { ICompanyFormationHasShareHolder } from "../../../../Interfaces/ICompanyFormationHasShareHolder";
import { ICompanyFormationAnswer } from "../../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../../Interfaces/ICompanyFormationQuestion";
import { ICompanyFormationOption } from "../../../../Interfaces/ICompanyFormationOption";
import { IContact, IContactFullObject } from "../../../../Interfaces/IContact";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { ShareHolderEdit } from "../Founding/Childs/ShareHolder/ShareHolderEdit";
import { academicTitleArray } from "../../../../StaticArraies/academicTitleArray";
import { genderArray } from "../../../../StaticArraies/genderArray";
import { RepresentationSummary } from "../Founding/Childs/Summary/RepresentationSummary";
import { ClientInformation } from "../../../generic/ClientInformation";
//import {SummaryCompanyData} from "./SummaryComponentns/SummaryCompanyData";
import { useTranslation } from "react-i18next";
import { IConfig } from "../../../../Interfaces/IConfig";
import { checkTranslation } from "../../../core/standardFunctions";

export interface IProps {
  configObject: IConfig;
  handleBack: Function;
  handleNext: Function;
  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  companyFormationShareArray: ICompanyFormationShare[];
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  companyFormationQuestionArray: ICompanyFormationQuestion[];
  companyFormationOptionArray: ICompanyFormationOption[];
}

const checkString = (stringToCheck: string | undefined) => {
  if (stringToCheck == undefined || stringToCheck === "") {
    return "k.A.";
  } else {
    return stringToCheck;
  }
};

export const SummaryCompanyChange: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [idShareHolder, setIdShareHolder] = useState<number | null>(null);

  const [personObject, setPersonObject] = useState<IContact>({
    idContact: -1,
    idCompany: -1,
    idGender: 1,
    idAcademicTitle: 1,
    FirstName: "",
    LastName: "",
    Email: "",
  } as IContact);

  const [companyObject, setCompanyObject] = useState<ICompany>({
    idCompany: -1,
    Company: "",
    idBusinessForm: 1,
    idPostcode: null,
    Street: "",
    StreetNr: "",
    StreetAditional: "",
    PhoneNumber: "",
    Website: "",
    RegisterCourt: null,
    RegisterNumber: null,
    TaxNumber: null,
    SalesTaxNumber: null,
  });

  //
  const [isLoading, setIsLoading] = useState(false);
  const [wasSuccessfully, setWasSuccessfully] = useState(true);

  async function handleSubmit() {
    setIsLoading(true);
    //handleNext();
    const legalTransactionFullObject = {
      idLegalTransaction: -1,
      idLegalTransactionState: 1,
      idLegalTransactionType: 12,
      idContact: -1,
      ContactObject: {
        ...personObject,
        CompanyObject: companyObject,
      } as IContactFullObject,
      CompanyFormation: {
        ...props.companyFormationObject,
        ShareHolderArray: props.shareHolderArray,
        CompanyFormationShareArray: props.companyFormationShareArray,
        CompanyFormationHasShareHolderArray: props.shareHolderRelation,
        CompanyFormationAnswerArray: props.companyFormationAnswerArray,
        DocumentArray: [],
      } as ICompanyFormationFullObject,
    } as ILegalTransactionFullObject;

    fetch("/api/legaltransaction/createCompanyFomration", {
      method: "POST",
      headers: {
        "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
        "Content-type": "application/json",
      },
      body: JSON.stringify(legalTransactionFullObject),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error(`ERROR GET: ${res.status}`);
        }
      })
      .then((res) => {
        setIsLoading(false);
        props.handleNext();
      })
      .catch((error) => {
        console.error(error);
        setWasSuccessfully(false);
      });
  }

  return (
    <>
      <Dialog open={isLoading}>
        <DialogTitle>Bitte warten</DialogTitle>
        <DialogContent>
          Ihre Anfrage wird derzeitig hochgeladen.
          <Grid
            container
            sx={{ mt: 3 }}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              {wasSuccessfully ? (
                <CircularProgress />
              ) : (
                <Alert severity="error">Es ist ein Fehler aufgetretten!</Alert>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={idShareHolder !== null}
        onClose={() => setIdShareHolder(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {idShareHolder === null || idShareHolder === 0 ? (
            <>{t("new_person")}</>
          ) : (
            <>Person bearbeiten</>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {idShareHolder !== null && (
              <ShareHolderEdit
                idShareHolder={idShareHolder}
                handleClose={() => setIdShareHolder(null)}
                shareHolderArray={props.shareHolderArray}
                setShareHolderArray={props.setShareHolderArray}
                disableSelectCompanyType={
                  props.shareHolderArray.find(
                    (x) => x.idShareHolder === idShareHolder
                  )?.idBusinessForm === 1
                }
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{ mt: 5 }} />
      <Typography variant="h4" sx={{ mb: 3 }}>
        Abschluss
      </Typography>

      <Typography>
        Vielen Dank für Ihre Angaben. Im Folgenden stellen wir Ihnen eine
        Übersicht der von Ihnen eingegebenen Daten zur Verfügung. Bitte
        überprüfen Sie diese sorgfältig. Wir werden Sie auf dem Laufenden
        halten.
        <p />
        Haben Sie Fragen? Dann nehmen Sie gerne{" "}
        <a href={`mailto:${props.configObject.email}`}>Kontakt</a> zu Ihrem
        Notarbüro auf!
      </Typography>

      <Box sx={{ mt: 5 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Abschließende Angaben zur Änderung
      </Typography>
      <TextField
        label="Anmerkungen"
        size="small"
        fullWidth
        value={
          props.companyFormationObject.Remarks === null
            ? ""
            : props.companyFormationObject.Remarks
        }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setCompanyFormationObject({
            ...props.companyFormationObject,
            Remarks: event.target.value === "" ? null : event.target.value,
          })
        }
        multiline
        rows={5}
      />

      <Box sx={{ mt: 5 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Zusammenfassung
      </Typography>
      <Grid container sx={{ pl: 10 }} spacing={2}>
        <Grid item sm={4}>
          <b>Firmennamen:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.CompanyName)}
        </Grid>

        <Grid item sm={4}>
          <b>Adresse:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.Street)}{" "}
          {checkString(props.companyFormationObject.StreetNr)}
        </Grid>

        <Grid item sm={4}></Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.Postcode)}{" "}
          {checkString(props.companyFormationObject.City)}
        </Grid>

        <Grid item sm={4}>
          <b>Geschäftszweck:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.PurposeOfTheCompany)}
        </Grid>

        <Grid item sm={4}>
          <b>Beteiligte:</b>
        </Grid>
        <Grid item sm={8}>
          {props.shareHolderArray.length}
        </Grid>

        {(props.companyFormationObject.idBusinessForm === 8 ||
          props.companyFormationObject.idBusinessForm === 9) && (
          <>
            <Grid item sm={4}>
              <b>Stammkapital:</b>
            </Grid>
            <Grid item sm={8}>
              {props.companyFormationObject.ShareCapital.toLocaleString(
                "de-DE",
                {
                  style: "currency",
                  currency: "EUR",
                }
              )}
            </Grid>

            <Grid item sm={4}>
              <b>Anteile:</b>
            </Grid>
            <Grid item sm={8}>
              {props.companyFormationShareArray
                .map((x) => x.AmountOfShares)
                .reduce((a, b) => a + b, 0)}
            </Grid>
          </>
        )}

        {/*
                    // Bestimmen, ob musterprokoll möglich ist
                    (
                        // online-if: nur GmbH oder UG
                        (
                            (props.companyFormationObject.idBusinessForm === 7 || props.companyFormationObject.idBusinessForm === 8)
                            // max. 3 Gesellschafter
                            && (props.shareHolderRelation.filter(x => x.idShareHolderType === 1).length <= 3)
                            // genau ein 1 geschöftsführer
                            && (props.shareHolderRelation.filter(x => x.idShareHolderType === 2).length === 1)
                            // und kein Prokuristen
                            && (props.shareHolderRelation.filter(x => x.idShareHolderType === 3).length === 0)
                        ) &&
                        <>
                            <Grid item sm={4}><Box sx={{mt: 1}}><b>Musterprotokoll:</b></Box></Grid>
                            <Grid item sm={8}>
                                <TextField
                                    size="small"
                                    type="number"
                                    fullWidth
                                    value={(props.companyFormationObject.useSampleProtocol) ? "true" : "false"}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCompanyFormationObject({
                                        ...props.companyFormationObject,
                                        useSampleProtocol: (event.target.value === "true")
                                    })}
                                    select
                                >
                                    <MenuItem key={`useSampleProtocol-true`} value="true">Ja</MenuItem>
                                    <MenuItem key={`useSampleProtocol-false`} value="false">Nein</MenuItem>
                                </TextField>
                            </Grid>
                        </>
                    )


            <CompanyFormationAnswerSummary
                companyFormationAnswerArray={props.companyFormationAnswerArray}
                companyFormationOptionArray={props.companyFormationOptionArray}
                companyFormationQuestionArray={props.companyFormationQuestionArray}
            />
                */}
      </Grid>

      <Box sx={{ mt: 10 }} />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Beteiligte
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{checkTranslation(t, "title", "Titel")}</TableCell>
            <TableCell>{checkTranslation(t, "Gender", "Geschlecht")}</TableCell>
            <TableCell>
              {checkTranslation(t, "first_name", "Vorname")}
            </TableCell>
            <TableCell>
              {checkTranslation(t, "last_name", "Nachname")}
            </TableCell>
            <TableCell>Rollen</TableCell>
            <TableCell>{checkTranslation(t, "actions", "Aktionen")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.shareHolderArray.map((x) => (
            <TableRow>
              {x.idBusinessForm === 1 ? (
                <>
                  <TableCell>
                    {x.idAcademicTitle === 1
                      ? ""
                      : checkString(
                          academicTitleArray.find(
                            (y) => y.idAcademicTitle === x.idAcademicTitle
                          )?.AcademicTitle
                        )}
                  </TableCell>
                  <TableCell>
                    {checkString(
                      genderArray.find((y) => y.idGender === x.idGender)?.Gender
                    )}
                  </TableCell>

                  <TableCell>{x.FirstName}</TableCell>
                  <TableCell>{x.LastName}</TableCell>
                </>
              ) : (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell colSpan={2}>{x.CompanyObject.Company}</TableCell>
                </>
              )}

              <TableCell>
                {props.shareHolderRelation
                  .filter((y) => y.idShareHolder === x.idShareHolder)
                  .map((x) =>
                    x.idShareHolderType === 1
                      ? "Gesellschafter"
                      : x.idShareHolderType === 2
                      ? "Geschäftsführer"
                      : x.idShareHolderType === 3
                      ? "Prokurist"
                      : x.idShareHolderType === 4
                      ? "GbR: Konkrete Vertretung"
                      : "Fehler"
                  )
                  .join(", ")}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => setIdShareHolder(x.idShareHolder)}>
                  <Search />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <RepresentationSummary
        shareHolderArray={props.shareHolderArray}
        shareHolderRelation={props.shareHolderRelation}
      />

      <ClientInformation
        configObject={props.configObject}
        disableHasAccount={
          props.configObject.Module.find((x) => x === "company_formation") ===
          undefined
        }
        contactFullObject={
          {
            ...personObject,
            CompanyObject: companyObject,
          } as IContactFullObject
        }
        setContactFullObject={(localFullObject: IContactFullObject) => {
          setPersonObject(localFullObject);
          setCompanyObject(localFullObject.CompanyObject);
        }}
        handleBack={() => props.handleBack()}
        handleSave={handleSubmit}
        isTranslationEnabled={false}
      />
    </>
  );
};
